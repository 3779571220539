<div class="row mb-3">
    <div class="col-4">
        <label>
            Total Impressions
        </label>
    </div>

    <div class="col-lg-9 col-12 d-flex align-items-center">
        <input class="form-control flex-grow-1" [disabled]="true" type="text" [(ngModel)]="totalImpresions">
        <button *ngIf="isSocialGeneratedByContent && baseCampaigns?.length > 0" (click)="handleButtonClick()" pButton class="p-button-secondary" style="margin: 10px 0 10px 10px;" [label]="'Content Campaign'"></button>
    </div>
</div>


<div *ngIf="!isSocialGeneratedByContent && !!addOns && !!addOns.length" class="row mb-3">
    <div class="col-5">
        <label>
            Add-on Impressions
        </label>
    </div>

    <div class="col-8 mb-3 d-flex align-items-center">
        <p-multiSelect class="flex-grow-1 me-2" [disabled]="!canEditImpressions || disabled" [options]="addOns" [(ngModel)]="selectedAddOns" (onChange)="onSelectAdOn($event)" itemSize="30" selectedItemsLabel="{0} items selected">
            <ng-template let-option pTemplate="item">
                <div [innerHTML]="option.title"></div>
            </ng-template>
        </p-multiSelect>
        <button *ngIf="baseCampaigns?.length > 0" (click)="handleButtonClick()" pButton class="p-button-secondary" style="margin: 10px 0 10px 10px;" [label]="baseCampaign.campaignType == enumAccountType.Content ? 'Social Campaigns' : 'Content Campaign'"></button>
    </div>
    <div class="col-lg-9 col-12">
        <input class="form-control" [disabled]="true" type="text" [(ngModel)]="addOnImpressions">
    </div>
</div>

<inventory-campaigns-dialog #inventoryCampaignsDialog></inventory-campaigns-dialog>