import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { faArrowRightFromFile, faArrowUpRightFromSquare, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { CampaignController } from '@Workspace/_generated/services';
import { IGridOptions, IPagingResult } from '@Workspace/interfaces';
import { PageLoaderService } from '@Workspace/services';
import { GridComponent } from '../grid/rc-grid.component';
import { Subject } from 'rxjs';

@Component({
    selector: 'inventory-campaigns-dialog',
    templateUrl: './inventory-campaigns-dialog.html',
    styleUrls: ['./inventory-campaigns-dialog.scss'],
})
export class InventoryCampaignsDialog implements OnInit, OnDestroy {
    isVisible: boolean = false;
    baseCampaigns: IBaseCampaignDto[];
    options: IGridOptions;

    ngUnsubscribe = new Subject();
    @ViewChild(GridComponent) GridComponent: GridComponent;

    constructor(
        public campaignController: CampaignController,
        public pageLoader: PageLoaderService) {
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    openDialog(campaigns: IBaseCampaignDto[]) {
        this.options = {} as IGridOptions;
        this.options.isOData = false;
        this.options.columns = [
            {
                label: 'Name',
                value:'name',
                width: '75%'
            },
            {
                label: 'Impressions',
                value: 'impressions',
                width: '25%'
            }
        ];
        let data: IPagingResult = {
            data: campaigns,
            skip: 0,
            take: 100,
            totalCount:100
        };
        this.options.data = data;
        this.isVisible = true;
        this.baseCampaigns = campaigns;
    }

    cancel(){
        this.isVisible = false;
    }

    handleHubCampaign(id){
        if(!!id){
            let url = "/#/campaign/" + id + "/campaign-upload-fan365";
            window.open(window.location.origin + url, '_blank');
        }
    }
}