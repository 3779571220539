<p-dialog [modal]="true" [(visible)]="isVisible" (onHide)="cancel()">
    <p-header>All Social Campaigns generated from this Content campaign</p-header>

    <div>
        <div class="text-muted" *ngIf="baseCampaigns?.length == 0" style="text-align: center; padding-top: 5em; padding-bottom: 5em;">
            <strong>There aren't any Social campaign.</strong>
        </div>

        <div class="row" *ngIf="baseCampaigns?.length > 0">
            <div class="col-12">
                <rc-grid [options]="options"> 
                    <ng-template pTemplate="header" let-item>
                        <tr>
                            <th *ngFor="let column of options?.columns"
                                [ngStyle]="{'width':column.width}" class="p-3">
                                {{column.label}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                        <tr (click)="handleHubCampaign(rowData.id)">
                            <td>
                                <span>
                                    {{ rowData.name }}
                                </span>
                            </td>
                            <td>
                                <span>
                                    {{ rowData.impressions }}
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </rc-grid>
            </div>
        </div>
    </div> 

    <p-footer>
        <div class="mb-3 d-flex justify-content-end">
            <button (click)="cancel()" pButton class="px-3 flat-btn pull-left " label="Close"></button>
        </div>
    </p-footer>

</p-dialog>