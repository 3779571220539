import { Component, OnInit, Input, Output, HostListener, EventEmitter, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CampaignController } from '@Workspace/_generated/services';
import { eEntityStatus, eCampaignStatus, eFileType, eChannelType, eStandardCampaginChannel, eCreativeType, eUserRole, eStoriesType, eAccountType, eInventoryTypeOAS } from '@Workspace/_generated/enums';
import { IBaseCampaignDto, IFormFileWrapper, ICreativeAssetDto, IFileDto, ICreativeDto } from '@Workspace/_generated/interfaces';
import { CartItemService } from '../card-item/card-item.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '@Workspace/auth';
import { CampaignService } from '../../../mogo/services/campaign.service';
import * as FileSaver from 'file-saver';
import { Constants, Functions } from '@Workspace/common';

@Component({
    selector: 'file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUpload implements OnInit, OnDestroy {
    constructor(
        public campaignController: CampaignController,
        public messageService: MessageService,
        private cardItemService: CartItemService,
        private sanitizer: DomSanitizer,
        private authService: AuthService,
        private campaignService: CampaignService
    ) { }

    @Input() height: number;
    @Input() width: number;
    @Input() filetypes: string;
    @Input() size: number;
    @Input() footer: boolean;
    @Input() asset: number;
    @Input() isApprove: boolean;
    @Input() headerTitle: string;
    @Input() isApprovedReported: boolean;
    @Input() reviewPage: boolean;
    @Input() landingPage: string;
    @Input() header: boolean;
    @Input() type: string = 'All types';
    @Input() canDelete: boolean = false;
    @Input() isHero: boolean = false;
    @Input() disableLandingPage: boolean = false;
    @Input() creative: ICreativeDto;
    @Input() campaign: IBaseCampaignDto;
    @Input() bulkUpload: boolean;
    @Input() storiesType: eStoriesType;

    @Output('onFilesDropped') onFilesDropped: EventEmitter<FileList> = new EventEmitter<FileList>();
    @Output() Basecampaign: EventEmitter<IBaseCampaignDto> = new EventEmitter();
    @Output() openPreviewEvent: EventEmitter<any> = new EventEmitter();
    @Output() onAssetDelete: EventEmitter<any> = new EventEmitter();
    @Output() validationLandingUrlMessageText = new EventEmitter<string>();
    @Output() uploadValidationMessage: EventEmitter<any> = new EventEmitter();

    @Output('isUploadOk') isUploadOk: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('uploaderZip') uploaderZip: ElementRef;

    url: any;
    zip: any;
    border: boolean = false;
    originalWidth: number = 0;
    originalHeight: number = 0;
    draggedOver: boolean = false;
    isPicture: boolean = false;
    isVideo: boolean = false;
    isAudio: boolean = false;
    approvalComment: string;
    index: number;
    dimension: string;
    creativeAsset: ICreativeAssetDto;
    landingurl: string;
    validationMessage: string;
    currentUser = this.authService.userSettings;
    fileTypeId: eFileType;
    acceptTypes: string = '';
    validationLandingUrl: boolean = true;
    enumCreativeType = eStandardCampaginChannel;
    enumUserRole = eUserRole;
    hideValidationMessage: boolean;
    fileToUpload: any;
    constants = Constants;
    size150KB: string;
    size100KB: string;
    // size75KB: string;
    htmlToolTipError: boolean=false;
    creativeStartDate: Date;
    functions = Functions;
    maxNumberOfFlights: number = 1;

    ngOnInit() {

        this.size = parseInt(this.size + '');
        this.maxNumberOfFlights =  Math.max(...this.campaign.creatives.map(x => x.creativeGroup));
        //this.campaign.creatives.sort((x, y) => { return y.creativeGroup - x.creativeGroup })[0].creativeGroup;

        window.addEventListener("dragover", e => {
            e && e.preventDefault();
        }, false);

        window.addEventListener("drop", e => {
            e && e.preventDefault();
        }, false);
        
        this.creativeAsset = this.creative.creativeAssets[this.asset];
        
        if (this.creative.creativeAssets[this.asset].fileWrapper) {
            this.selectFile({target: {files: [this.creative.creativeAssets[this.asset].fileWrapper]}})
        }
        
        this.landingurl = this.creative.creativeAssets[this.asset] ? this.creative.creativeAssets[this.asset].imageUrl : null;
        
        this.changeFileType()
        // if (this.filetypes) {
        //     this.filetypes.split(",").forEach(x => {
        //         this.acceptTypes += '.' + x.toString().replace(/ /g, '') + ', ';
        //     });
        // }

        if ((this.campaign.account.isHubStandardCampaign || this.campaign.account.isCampaignCreation) && this.type != 'video') {
            this.height = !!this.height ? this.height : this.creative.creativeAssets[this.asset]?.height;
            this.width = !!this.width ? this.width : this.creative.creativeAssets[this.asset]?.width;
        }
        
        //is file for asset already uploaded to azure
        if (this.creative.creativeAssets[this.asset] && this.creative.creativeAssets[this.asset].file
            && this.creative.creativeAssets[this.asset].file.entityStatus == eEntityStatus.Active) {
                
            this.url = this.creative.creativeAssets[this.asset].file.urlWithToken;
            this.originalHeight = this.creative.creativeAssets[this.asset].height;
            this.originalWidth = this.creative.creativeAssets[this.asset].width;

            if (this.creative.creativeAssets[this.asset].file.fileType == 'gif' || this.creative.creativeAssets[this.asset].file.fileType == 'jpg' ||
                this.creative.creativeAssets[this.asset].file.fileType == 'png' || this.creative.creativeAssets[this.asset].file.fileType == 'jpeg')
                this.isPicture = true;

            if (this.creative.creativeAssets[this.asset].file.fileType == 'mp4' || this.creative.creativeAssets[this.asset].file.fileType == 'mov')
                this.isVideo = true;

            if (this.creative.creativeAssets[this.asset].file.fileType == 'wav' || this.creative.creativeAssets[this.asset].file.fileType == 'mp3' ||
                this.creative.creativeAssets[this.asset].file.fileType == 'mpeg' || this.creative.creativeAssets[this.asset].file.fileType == 'ogg')
                this.isAudio = true;

            if (this.creativeAsset.isValid == false)
                this.border = true;

        }

        else if(this.creative.creativeAssets[this.asset] && this.creative.creativeAssets[this.asset].file && this.creative.creativeAssets[this.asset].file.entityStatus && this.creative.creativeAssets[this.asset].file.entityStatus == eEntityStatus.Active
            && this.creative.creativeAssets[this.asset].fileWrapper){  
            this.proccessFile(this.creative.creativeAssets[this.asset].fileWrapper);
        }

        if (this.creative.creativeAssets[this.asset] && !!this.creative.creativeAssets[this.asset].fileAdditional && this.creative.creativeAssets[this.asset].fileAdditional.urlWithToken)
            this.zip = this.creative.creativeAssets[this.asset].fileAdditional.urlWithToken;

        //for FAN365 campaign and standard banner there is more than one valid standard image dimension 160x600,300x250,300x600,728x90,320x50,970x90,970x66,970x250
        if (this.campaign.accountTypeId == 'FAN365' &&
            (this.creative.creativeTypeId == eStandardCampaginChannel.Standard_Display_Banner
                || this.creative.creativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner
                || this.creative.creativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner)) {
            this.fileTypeId = eFileType.DisplayBanner;
            this.dimension = '160x600,300x250,300x600,728x90,320x50,970x90,970x66,970x250';
        }

        //for FAN365 campaign and premium banner there is more than one valid premium image dimension 970x250, 300x600
        if (this.campaign.accountTypeId == 'FAN365' && this.creative.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner) {
            this.dimension = '970x250,300x600,160x600,300x250,320x50,728x90,970x66,970x90';
            this.fileTypeId = eFileType.DisplayBanner;

        }

        if (this.campaign.campaignType == eAccountType.OAS && this.creative.creativeTypeId == eStandardCampaginChannel.OAS_Display) {
            var dimensionPageTakeoverRosDisplay = '300x250,970x90,320x50,728x90,300x600,970x250,320x480,970x66';
            var dimensionPremiumPageTakeover = '450x1100,1600x1600,900x380,1440x380,900x150,2600x2600,300x250,970x90,320x50,728x90,300x600,970x250,320x480,970x66,1920x350,1920x120,1024x512,1024x150';
            var dimensionGeoDemo = '300x250,970x90,320x50,728x90,300x600,970x250,320x480';
            var dimensionTradeDeal = '300x250,320x50,728x90,970x90';
            var dimensionShareOfVoice = '300x250,970x90,320x50,728x90,300x600,970x250';
            
            this.size150KB = '300x250,728x90,970x90,970x250,300x600,150x50,320x480,450x600,1600x600,900x380,1440x380,900x150,2600x2600,970x66,320x50,120x35';
            this.size100KB = '1920x350,1920x120,1024x512,1024x150';
            // this.size75KB = '320x50,120x35';
            
            this.dimension = this.creative.name == 'Entitlement' ? '150x50' : (this.creative.name == 'Geo_Demo_Targeted' ? dimensionGeoDemo : (this.creative.name == 'Page_Takeover_Premium' ? dimensionPremiumPageTakeover : (this.creative.name == 'Trade_Deal' ? dimensionTradeDeal : (this.creative.name == 'Share_Of_Voice' ? dimensionShareOfVoice : dimensionPageTakeoverRosDisplay))));
            this.fileTypeId = eFileType.OASDisplay;

            if(this.campaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Page_Takeover 
                || this.campaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Page_Takeover_Premium)
                    this.size = 10485760; //10MB
        }

        if (this.campaign.campaignType == eAccountType.OAS && this.creative.creativeTypeId == eStandardCampaginChannel.OAS_Video) {
            this.dimension = '640x480,640x483';
            this.fileTypeId = eFileType.OASVideo;
        }

        if (this.campaign.campaignType == eAccountType.Social) {
            this.fileTypeId = eFileType.SocialCampaign;
        }

        //for HS campaign and display banners
        if (this.campaign.accountTypeId == 'HS' && (this.creative.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner || this.creative.creativeTypeId == eStandardCampaginChannel.Standard_Display_Banner
            || this.creative.creativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner || this.creative.creativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner)) {
            this.dimension = this.width + 'x' + this.height;
            this.fileTypeId = eFileType.DisplayBanner;
        }


        //for HMP campaign and standard banner there is more than one valid standard image dimension 160x600,300x250,728x90,320x50
        if (this.campaign.accountTypeId == 'HMP' &&
            (this.creative.creativeTypeId == eStandardCampaginChannel.Standard_Display_Banner || this.creative.creativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner
                || this.creative.creativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner || this.creative.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner)) {
            this.fileTypeId = eFileType.DisplayBanner;
            //this.dimension = '160x600,300x250,728x90,320x50, 300x600';
        }

        //for all campaign and Social creative there is more than one valid image dimension 1200x628, 1080x1080 and file size is limit to 1MB
        if (this.creative.channelId == eChannelType.Social && this.creative.creativeTypeId != eStandardCampaginChannel.LinkedIn_News_Feed_Image && this.creative.creativeTypeId != eStandardCampaginChannel.LinkedIn_News_Feed_Video && this.creative.creativeTypeId != eStandardCampaginChannel.LinkedIn_Dynamic_News_Feed_Carousel
            && this.creative.creativeTypeId != eStandardCampaginChannel.TikTok_In_Feed_Ads && (this.creative.creativeTypeId != eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video && this.creative.creativeTypeId != eStandardCampaginChannel.Facebook_Static_News_Feed_Video)
            || (this.asset > 0 && this.creative.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video) || this.creative.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed
            || (this.creative.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed && this.asset == 0)) {
            {
                this.dimension = '1080x1080(recommended), 1200x628';
                this.fileTypeId = eFileType.FacebookNewsFeedStaticImage;
                this.size = this.campaign.accountTypeId == 'FAN365' ? 4294967296 : 1048576;
            }
        }
        if(this.creative.channelId == eChannelType.Social && this.creative.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed && this.asset == 1)
            {
                this.dimension = '1080x1920';
                this.fileTypeId = eFileType.FacebookNewsFeedStaticImage;
                this.size = 1048576;
            }

        //for all campaign and Social facebook creative with video
        if ((this.creative.channelId == eChannelType.Social
            && this.creative.creativeTypeId != eStandardCampaginChannel.Facebook_Static_News_Feed_Video)
            || (this.creative.channelId == eChannelType.Social && this.asset == 0 && this.creative.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video)) 
        {
            this.fileTypeId = eFileType.FacebookNewsFeedDynamicVideo;
        }

        if (this.creative.channelId == eChannelType.Other && this.creative.creativeTypeId == eStandardCampaginChannel.Native_Image) {
            this.fileTypeId = eFileType.NativeImage;
        }

        if (this.creative.channelId == eChannelType.Other && this.creative.creativeTypeId == eStandardCampaginChannel.Native_Video) {
            this.fileTypeId = eFileType.NativeVideo;
        }

        if (this.creative.channelId == eChannelType.Social && this.creative.creativeTypeId == eStandardCampaginChannel.Stories) {
            this.fileTypeId = eFileType.Stories;
            if (this.storiesType == eStoriesType.Stories) {
                this.size = 1048576;
                if(this.campaign.account.isHubStandardCampaign){
                    this.dimension = '1080x1920';
                }
                else{
                    this.dimension = '1080x1080,1080x1920';
                }
            } else if (this.storiesType == eStoriesType.Reels) {
                this.dimension = '1080x1920';
                this.size = 10485760;
            } else { 
                this.dimension = '1080x1920';
                this.size = 1048576;
            }
        }

        if (this.creative.channelId == eChannelType.Social && this.creative.creativeTypeId == eStandardCampaginChannel.Lead_Generation) {
            this.fileTypeId = eFileType.LeadGeneration;
        }

        if (this.creative.channelId == eChannelType.Social && this.creative.creativeTypeId == eStandardCampaginChannel.Instant_Experience) {
            this.dimension = '1080x1080';
            this.fileTypeId = eFileType.InstantExperience;
        }

        if(this.creative.channelId == eChannelType.Social && (this.creative.creativeTypeId == eStandardCampaginChannel.LinkedIn_News_Feed_Image || this.creative.creativeTypeId == eStandardCampaginChannel.LinkedIn_News_Feed_Video)){
            this.dimension = '1200x1200,1200x628';
            this.fileTypeId = this.creative.creativeTypeId == eStandardCampaginChannel.LinkedIn_News_Feed_Image ? eFileType.LinkedinImage : eFileType.LinkedinVideo;
        }

        if(this.creative.channelId == eChannelType.Social && this.creative.creativeTypeId == eStandardCampaginChannel.LinkedIn_Dynamic_News_Feed_Carousel)
            this.fileTypeId = eFileType.LinkedinCarousel;

        if(this.creative.channelId == eChannelType.Social && this.creative.creativeTypeId == eStandardCampaginChannel.TikTok_In_Feed_Ads)
            this.fileTypeId = eFileType.TikTokInFeedAds;

        if (this.creative.channelId == eChannelType.Other && this.creative.creativeTypeId == eStandardCampaginChannel.Spotify_Audio && this.isPicture == true) {
            this.fileTypeId = eFileType.SpotifyAudioStatic;
        }

        if (this.creative.channelId == eChannelType.Other && this.creative.creativeTypeId == eStandardCampaginChannel.Spotify_Video && this.isPicture == true) {
            this.fileTypeId = eFileType.SpotifyVideoStatic;
        }

        if(this.campaign.campaignType == eAccountType.Content)
            this.fileTypeId = eFileType.ContentCampaign;

        this.getLandingPage();
    }

    changeFileType(){
        this.acceptTypes = '';
        if (this.filetypes) {
            this.filetypes.split(",").forEach(x => {
                this.acceptTypes += '.' + x.toString().replace(/ /g, '') + ', ';
            });
        }
    }

    @HostListener('dragover', ['$event']) onDragover(e) {
        e.preventDefault();
        e.stopPropagation();

        this.draggedOver = true;
    }

    @HostListener('dragleave', ['$event']) onDragleave(e) {
        e.preventDefault();
        e.stopPropagation();

        this.draggedOver = false;
    }

    @HostListener('drop', ['$event']) onDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        if(this.checkIsUploadEnabled()){
            let transfer = e.dataTransfer;
            for (var i = 0; i < transfer.files.length; i++) {
                if ((this.campaign.campaignType == eAccountType.Social || this.campaign.campaignType == eAccountType.Content)
                    && this.bulkUpload) {
                    this.socialAssetBulkUpload(transfer.files[i]);
                } 
                else if (transfer.files[i].type.split("/")[1].indexOf('zip') != -1 || transfer.files[i].type.split("/")[1] == '7zip' || transfer.files[i].type.split("/")[1] == 'rar')
                    this.uploadZip(transfer);
                else
                    this.proccessFile(transfer.files[i]);
            }
        }
    }

    openPreview() {
        this.openPreviewEvent.emit(this.creative.creativeAssets[this.asset]);
    }

    openZip(link) {
        if (!!link) {
            FileSaver.saveAs(link, this.creative.creativeAssets[this.asset].fileAdditional.description);
        }
    }
    redirect(link) {
        window.open(link, "_blank");
    }

    getLandingPage() {
        if (!!this.landingPage && !!this.creativeAsset.file)
            console.log("creativeAsset");
        else {
            let imageUrl = !!this.creativeAsset ? this.creativeAsset.imageUrl : null;
        }
    }

    validateLandingPage() {
        if (this.url) {
            if (!!this.creative.creativeAssets[this.asset].imageUrl || !!this.landingPage) {
                let textToMacth = this.creative.creativeAssets[this.asset].imageUrl ? this.creative.creativeAssets[this.asset].imageUrl : this.landingPage;
                // var regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?%#[\]@!\$&'\(\)\*\+,;=.]+$/;
                var regex = /^[a-zA-Z0-9]+[^.\n]*[.][^.\n]+([.][^.\n]+)*$/;
                var found = textToMacth.match(regex);
                if (found != null) {
                    this.creative.creativeAssets[this.asset].isLandingPageExist = true;
                    this.validationLandingUrl = true;
                    this.cardItemService.updatedDataSelection(this.campaign);
                }

                else {
                    this.creative.creativeAssets[this.asset].isLandingPageExist = false;
                    this.validationLandingUrl = false;
                    this.validationLandingUrlMessageText.emit('Please enter a valid landing page.');
                    this.cardItemService.updatedDataSelection(this.campaign);
                }

            }
            else {
                this.creative.creativeAssets[this.asset].isLandingPageExist = false;
                this.validationLandingUrl = false;
                this.validationLandingUrlMessageText.emit('Missing Landing URL page for uploaded asset.');
                this.cardItemService.updatedDataSelection(this.campaign);
            }
        }

        else {
            this.creative.creativeAssets[this.asset].isLandingPageExist = null;
            this.cardItemService.updatedDataSelection(this.campaign);
        }

        this.landingurl = this.creative.creativeAssets[this.asset].imageUrl;
    }

    uploadZip(event) {
        if ((event.target && event.target.files) || event.files) {
            let file = event.target && event.target.files[0] ? event.target.files[0] : event.files[0];
            var reader = new FileReader();
            let filetype = file.type.split("/")[1];

            if (filetype.indexOf('zip') != -1 || filetype == '7zip' || filetype == 'rar') {
                this.zip = file.name;
                const wrapper = { file: file } as IFormFileWrapper;
                this.creative.creativeAssets[this.asset].fileAdditional = {} as IFileDto;
                this.creative.creativeAssets[this.asset].fileWrapperAdditional = file;
                this.creative.creativeAssets[this.asset].fileAdditional.fileName = file.name;
                this.creative.creativeAssets[this.asset].fileAdditional.sizeInBytes = file.size;
                this.creative.creativeAssets[this.asset].fileAdditional.fileType = filetype;
                this.creative.creativeAssets[this.asset].fileAdditional.fileTypeId = eFileType.DisplayBanner;
                this.creative.creativeAssets[this.asset].fileAdditional.description = file.name;
                this.creative.creativeAssets[this.asset].isChanged = true;
                this.htmlToolTipError=false;

                if(this.campaign.campaignType == eAccountType.OAS && this.creative.creativeAssets[this.asset].fileAdditional.sizeInBytes>204800){
                    this.creative.creativeAssets[this.asset].fileWrapperAdditional = null;
                    this.creative.creativeAssets[this.asset].fileAdditional = null;
                    this.zip=null;
                    this.htmlToolTipError=true;                    
                    this.cardItemService.updatedDataSelection(this.campaign);
                }
                else if (this.creative.creativeAssets[this.asset].file == null) {
                    this.creative.creativeAssets[this.asset].isUploadValid = false;
                    this.validationMessage = "Missing creative image";
                    this.border = true;
                }
                
                this.Basecampaign.emit(this.campaign);
            }
        }
    }

    selectFile(event) {
        if (event.target.files && this.checkIsUploadEnabled()) {
            this.fileToUpload = event.target.files[0];

            if ((this.campaign.campaignType == eAccountType.Social || this.campaign.campaignType == eAccountType.Content)
                && this.bulkUpload) {
                this.socialAssetBulkUpload(event.target.files[0]);
            }
            else{
                this.proccessFile(event.target.files[0]);
            }
        }
    }

    socialAssetBulkUpload(file) {
        let filetype = file.type.split("/")[1];
        this.hideValidationMessage = true;
        this.fileTypeId = eFileType.SocialCampaign;
        this.campaignService.setFileTypeId(this.fileTypeId);

        if (filetype == 'gif' || filetype == 'jpg' || filetype == 'png' || filetype == 'jpeg') {
            var img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = () => {
                window.URL.revokeObjectURL(img.src);

                this.originalHeight = img.height;
                this.originalWidth = img.width;
            }
        }

        let newAsset = {} as ICreativeAssetDto;
        newAsset.id = Constants.GuidEmpty;
        newAsset.width = this.originalWidth;
        newAsset.height = this.originalHeight;
        newAsset.isValid = null;
        newAsset.creativeId = this.creative.id;
        newAsset.fileType = file.type.split("/")[1];;
        newAsset.fileSize = file.size;
        newAsset.imageUrl = null;
        newAsset.fileName = file.name;
        newAsset.url = null;
        newAsset.isChanged = true;
        newAsset.isUploadValid = true;
        newAsset.isDeleted = false;
        const wrapper = { file: file } as IFormFileWrapper;
        newAsset.fileWrapper = file;
        newAsset.file = {} as IFileDto;
        newAsset.file.fileTypeId = this.fileTypeId;
        this.creative.creativeAssets.push(newAsset);

        this.campaign.creatives[0] = this.creative;

        this.cardItemService.updatedDataSelection(this.campaign);
        this.Basecampaign.emit(this.campaign);
    }

    getLink() {
        if (!!this.creativeAsset.imageUrl && this.creativeAsset.imageUrl.indexOf('https://') > -1)
            return this.creativeAsset.imageUrl
        else
            return 'https://' + this.creativeAsset.imageUrl;
    }

    onVideoLoaded($event){
        if (this.storiesType || this.creative.creativeTypeId == eStandardCampaginChannel.LinkedIn_News_Feed_Video || this.creative.creativeTypeId == eStandardCampaginChannel.TikTok_In_Feed_Ads) {
            let validDimension = !!this.dimension ? this.dimension.toString().replace(/ /g, '').replace(/\(recommended\)/g, '') : null;
            if ((!!this.width && this.width != $event.target.videoWidth)
                || (!!this.height && this.height != $event.target.videoHeight)
                || (!!validDimension && validDimension.split(",").indexOf($event.target.videoWidth.toString() + 'x' + $event.target.videoHeight.toString()) == -1)) 
                {
                    this.deleteUpload();
                    this.border = true;
                    this.creative.creativeAssets[this.asset].isUploadValid = false;
                    this.cardItemService.updatedDataSelection(this.campaign);
                    this.validationMessage = "Incorrect file dimensions";
                    this.url = null;
                    this.isPicture = false;
                    this.isVideo = false;
                    this.isAudio = false;
                    this.originalWidth = 0;
                    this.originalHeight = 0;
                }
                else if((this.creative.creativeTypeId == eStandardCampaginChannel.LinkedIn_News_Feed_Video && ($event.target.duration < 15 || $event.target.duration > 30))
                    || (this.creative.creativeTypeId == eStandardCampaginChannel.TikTok_In_Feed_Ads && $event.target.duration < 5)){
                        this.deleteUpload();
                        this.border = true;
                        this.creative.creativeAssets[this.asset].isUploadValid = false;
                        this.cardItemService.updatedDataSelection(this.campaign);
                        this.validationMessage = this.creative.creativeTypeId == eStandardCampaginChannel.LinkedIn_News_Feed_Video ? "The video must be between 15 and 30 seconds long."
                        : "The video must be at least 5 seconds long.";
                        this.url = null;
                        this.isPicture = false;
                        this.isVideo = false;
                        this.isAudio = false;
                        this.originalWidth = 0;
                        this.originalHeight = 0;
                }
                else if(this.storiesType){
                    this.creative.detailJson.storiesType = this.storiesType;
                }
        }
        if(this.campaign.campaignType == eAccountType.FAN365){
            this.originalWidth = $event.target.videoWidth;
            this.originalHeight = $event.target.videoHeight;

            this.creative.creativeAssets[this.asset].height = this.originalHeight;
            this.creative.creativeAssets[this.asset].width = this.originalWidth;

            if(this.creative.creativeGroup > 1 && !this.campaign.creatives.filter(x => x.entityStatus != eEntityStatus.Deleted && x.creativeGroup == 1 && x.creativeTypeId == this.creative.creativeTypeId)[0].creativeAssets.some(x => x.entityStatus != eEntityStatus.Deleted && x.width == this.originalWidth && x.height == this.originalHeight)){
                let message = "Video with dimension that you uploaded does not exist on the 1st flight.";
                this.uploadValidationMessage.emit(message);
            }
        }
        else{
            this.originalWidth = $event.target.videoWidth;
            this.originalHeight = $event.target.videoHeight;

            this.creative.creativeAssets[this.asset].height = this.originalHeight;
            this.creative.creativeAssets[this.asset].width = this.originalWidth;
        }
    }

    proccessFile(file) {
        let filetype = file.type.split("/")[1];
        this.hideValidationMessage = true;

        //if file extension is one of valid extension for this upload
        if (this.filetypes.includes(filetype) || this.filetypes == "all") {
            //reader.readAsDataURL(file);
            //reader.onload = (event: any) => {
            //if file is image
            if (filetype == 'gif' || filetype == 'jpg' || filetype == 'png' || filetype == 'jpeg') {
                var img = new Image();
                img.src = window.URL.createObjectURL(file);
                img.onload = () => {
                    window.URL.revokeObjectURL(img.src);

                    this.originalHeight = img.height;
                    this.originalWidth = img.width;
                    // this.height = !!this.height ? this.height.toString().replace(/ /g, '') : null;
                    // this.width = !!this.width ? this.width.toString().replace(/ /g, '') : null;
                    let validDimension = !!this.dimension ? this.dimension.toString().replace(/ /g, '').replace(/\(recommended\)/g, '') : null;
                    
                    if(this.campaign.campaignType == eAccountType.OAS){
                        if(this.campaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Page_Takeover 
                            || this.campaign.campaignInventoryItems[0]?.inventoryItemOAS?.inventoryType == eInventoryTypeOAS.Page_Takeover_Premium)
                                this.size = 10485760; //10MB
                        else {
                            let dimension = this.originalWidth + 'x' + this.originalHeight;
                            if(this.size150KB.includes(dimension))
                                this.size = 153600;
                            else if(this.size100KB.includes(dimension))
                                this.size = 102400;  
                            // else if(this.size75KB.includes(dimension))
                            //     this.size = 76800;
                        }
                    }

                    //this.dimension = !!this.dimension ? this.dimension.toString().replace(/ /g, '') : null;
                    // if ((!!this.width && this.width.split(",").indexOf(img.width.toString()) == -1) ||
                    //     (!!this.height && this.height.split(",").indexOf(img.height.toString()) == -1) ||
                    if ((!!this.width && this.width != img.width) ||
                        (!!this.height && this.height != img.height) ||
                        (!!validDimension && validDimension.split(",").indexOf(img.width.toString() + 'x' + img.height.toString()) == -1)) {
                        this.deleteUpload();
                        this.border = true;
                        this.creative.creativeAssets[this.asset].isUploadValid = false;
                        if (this.creative.creativeAssets[this.asset].imageUrl || !!this.landingPage) {
                            this.validationLandingUrl = true;
                            this.creative.creativeAssets[this.asset].isLandingPageExist = null;
                        }
                        this.cardItemService.updatedDataSelection(this.campaign);
                        this.validationMessage = "Incorrect file dimensions"
                        this.url = null;
                        this.isPicture = false;
                        this.isVideo = false;
                        this.isAudio = false;
                    }
                    // else if (file.size > parseInt(this.size.split(",")[0])) {
                   else if (file.size > this.size) {
                        this.deleteUpload();
                        this.border = true;
                        this.creative.creativeAssets[this.asset].isUploadValid = false;
                        if (this.footer && !this.creative.creativeAssets[this.asset].imageUrl && !this.landingPage) {
                            this.validationLandingUrl = true;
                            this.creative.creativeAssets[this.asset].isLandingPageExist = null;
                        }
                        this.cardItemService.updatedDataSelection(this.campaign);
                        this.validationMessage = "Incorrect file size"
                        this.url = null;
                        this.isPicture = false;
                        this.isVideo = false;
                        this.isAudio = false;
                    }
                    else {
                        this.border = false;
                        if(this.campaign.campaignType == eAccountType.FAN365 && this.creative.creativeGroup > 1 && !this.validateAssetWithFirstFlight()){
                            let message = "This dimension is not recommended for asset because it does not exist on 1st flight.";
                            this.uploadValidationMessage.emit(message);
                        }

                        //this.url = this.sanitizer.bypassSecurityTrustUrl(event.target.result);
                        // this.url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));

                        let blob = new Blob([file], { type: file.type });
                        const urlPattern = /^blob:http.+/;
                        const url = window.URL.createObjectURL(blob);

                        if (urlPattern.test(url)) {
                            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url) as SafeResourceUrl;
                        } 
                        this.isPicture = true;
                        if (this.creative.channelId == eChannelType.Other && this.creative.creativeTypeId == eStandardCampaginChannel.Spotify_Audio && this.isPicture == true) {
                            this.fileTypeId = eFileType.SpotifyAudioStatic;
                        }

                        if (this.creative.channelId == eChannelType.Other && this.creative.creativeTypeId == eStandardCampaginChannel.Spotify_Video && this.isPicture == true) {
                            this.fileTypeId = eFileType.SpotifyVideoStatic;
                        }
                        this.saveCampaignAsset(file);

                        if (this.footer && !this.creative.creativeAssets[this.asset].imageUrl && !this.landingPage) {
                            this.validationLandingUrl = false;
                            this.creative.creativeAssets[this.asset].isLandingPageExist = false;
                            this.cardItemService.updatedDataSelection(this.campaign);

                        }
                    }
                }
            }

            //if file is video
            else if (filetype == 'mp4' || (filetype == 'mov' && this.storiesType && this.storiesType == eStoriesType.Reels)) {
                if (file.size > this.size) {
                    this.deleteUpload();
                    this.border = true;
                    this.creative.creativeAssets[this.asset].isUploadValid = false;
                    this.cardItemService.updatedDataSelection(this.campaign);
                    this.validationMessage = "Incorrect file size"
                    this.url = null;
                    this.isPicture = false;
                    this.isVideo = false;
                    this.isAudio = false;
                    this.originalWidth = 0;
                    this.originalHeight = 0;
                }
                else {
                    this.border = false;

                    this.fileTypeId = (this.campaign.campaignType == eAccountType.OAS && this.campaign.campaignInventoryItems[0].inventoryItemOAS.kindOfCreative == eCreativeType.Video) ?
                        eFileType.OASVideo :
                        (this.creative.channelId == eChannelType.Social ? eFileType.FacebookNewsFeedDynamicVideo :
                            (this.creative.creativeTypeId == eStandardCampaginChannel.In_Stream_Video ? eFileType.InStreamVideo :
                                (this.creative.creativeTypeId == eStandardCampaginChannel.Spotify_Video ? eFileType.SpotifyVideo : eFileType.NativeVideo)));

                    //if (!!!this.dimension) {
                        this.saveCampaignAsset(file);
                   // }
                    // this.url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
                    //this.url = this.sanitizer.bypassSecurityTrustUrl(event.target.result);
                    
                    let blob = new Blob([file], { type: file.type });
                    const urlPattern = /^blob:http.+/;
                    const url = window.URL.createObjectURL(blob);

                    if (urlPattern.test(url)) {
                        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url) as SafeResourceUrl;
                    }
                    this.isVideo = true;
                    this.originalWidth = 0;
                    this.originalHeight = 0;
                    if (this.footer && !this.creative.creativeAssets[this.asset].imageUrl && !this.landingPage) {
                        this.validationLandingUrl = false;
                        this.creative.creativeAssets[this.asset].isLandingPageExist = false;
                        this.cardItemService.updatedDataSelection(this.campaign);

                    }
                }              
            }

            // if file is audio wav, mp3, ogg
            else if (filetype == 'wav' || filetype == 'mp3' || filetype == 'mpeg' || filetype == 'ogg') {
                if (file.size > this.size) {
                    this.deleteUpload();
                    this.border = true;
                    this.creative.creativeAssets[this.asset].isUploadValid = false;
                    this.cardItemService.updatedDataSelection(this.campaign);
                    this.validationMessage = "Incorrect file size"
                    this.url = null;
                    this.isPicture = false;
                    this.isVideo = false;
                    this.isAudio = false;
                }
                else {
                    this.border = false;
                    this.fileTypeId = eFileType.SpotifyAudio;
                    this.saveCampaignAsset(file);
                    // this.url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
                    //this.url = this.sanitizer.bypassSecurityTrustUrl(event.target.result);
                    let blob = new Blob([file], { type: file.type });
                    const urlPattern = /^blob:http.+/;
                    const url = window.URL.createObjectURL(blob);

                    if (urlPattern.test(url)) {
                        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url) as SafeResourceUrl;
                    }
                    this.isAudio = true;
                }
            }

            else {
                this.deleteUpload();
                this.border = true;
                this.creative.creativeAssets[this.asset].isUploadValid = false;
                if (this.footer && !this.creative.creativeAssets[this.asset].imageUrl && !this.landingPage) {
                    this.validationLandingUrl = true;
                    this.creative.creativeAssets[this.asset].isLandingPageExist = null;
                }
                this.cardItemService.updatedDataSelection(this.campaign);
                this.validationMessage = "Incorrect file type"
                this.url = null;
                this.isPicture = false;
                this.isVideo = false;
                this.isAudio = false;
            }

            //}
        }

        else {
            this.deleteUpload();
            this.border = true;
            this.creative.creativeAssets[this.asset].isUploadValid = false;
            if (this.footer && !this.creative.creativeAssets[this.asset].imageUrl && !this.landingPage) {
                this.validationLandingUrl = true;
                this.creative.creativeAssets[this.asset].isLandingPageExist = null;
            }
            this.cardItemService.updatedDataSelection(this.campaign);
            this.validationMessage = "Incorrect file type"
            this.url = null;
            this.isPicture = false;
            this.isVideo = false;
            this.isAudio = false;
        }

        this.campaignService.setFileTypeId(this.fileTypeId);
    }

    saveCampaignAsset(file) {
        this.creative.creativeAssets[this.asset].fileName = this.isHero ? "Hero_" + file.name : file.name;
        this.creative.creativeAssets[this.asset].fileSize = file.size;
        this.creative.creativeAssets[this.asset].fileType = file.type.split("/")[1];
        this.creative.creativeAssets[this.asset].height = this.originalHeight;
        this.creative.creativeAssets[this.asset].width = this.originalWidth;
        const wrapper = { file: file } as IFormFileWrapper;
        this.creative.creativeAssets[this.asset].fileWrapper = file;
        this.creative.creativeAssets[this.asset].isChanged = true;
        this.creative.creativeAssets[this.asset].isUploadValid = true;
        this.creative.creativeAssets[this.asset].imageUrl = this.landingurl;
        this.creative.creativeAssets[this.asset].file = {} as IFileDto;
        this.creative.creativeAssets[this.asset].file.fileTypeId = this.fileTypeId;
        this.creative.creativeAssets[this.asset].isDeleted = false;
        this.creative.creativeAssets[this.asset].isValid = null;

        this.cardItemService.updatedDataSelection(this.campaign);
        this.Basecampaign.emit(this.campaign);
    }

    deleteUpload() {
        this.checkDeleteUploadWarning();

        if(!this.reviewPage && this.functions.checkCanChangeAsset(this.creative.creativeGroup, this.creative.flightStartDate, this.campaign.startDate, this.campaign.campaignStatusId, this.campaign.statusId, this.campaign.campaignType, this.currentUser, this.creative.isBuilt, !!this.campaign.campaignInventoryItems ? this.campaign.campaignInventoryItems[0] : null, this.maxNumberOfFlights))
        {
            this.creative.creativeAssets[this.asset].fileName = null;
            this.creative.creativeAssets[this.asset].fileSize = null;
            this.creative.creativeAssets[this.asset].fileType = null;
            if(!((this.campaign.account.isHubStandardCampaign || this.campaign.account.isCampaignCreation) 
            && (this.creative.creativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner || this.creative.creativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner 
                || this.creative.creativeTypeId == eStandardCampaginChannel.Standard_Display_Banner || this.creative.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner))){
                this.creative.creativeAssets[this.asset].height = 0;
                this.creative.creativeAssets[this.asset].width = 0;
            }
            this.creative.creativeAssets[this.asset].fileWrapper = null;
            this.creative.creativeAssets[this.asset].isChanged = true;
            this.creative.creativeAssets[this.asset].isUploadValid = null;
            this.creative.creativeAssets[this.asset].imageUrl = null;
            this.creative.creativeAssets[this.asset].isValid = null;
            this.creative.creativeAssets[this.asset].isDeleted = true;
            this.zip = null;
            this.creative.creativeAssets[this.asset].fileAdditional = null;
            this.creative.creativeAssets[this.asset].fileWrapperAdditional = null;
            this.creative.creativeAssets[this.asset].file = null;

            this.creative.creativeAssets[this.asset].isChanged = true;

            this.url = null;
            this.isPicture = false;
            this.isVideo = false;
            this.isAudio = false;
            this.border = false;

            this.creative.creativeAssets[this.asset].isLandingPageExist = null;
            this.validationLandingUrl = true;

            if(this.uploaderZip != null)
                this.uploaderZip.nativeElement.value = '';
            this.cardItemService.updatedDataSelection(this.campaign);

            if (this.creative.creativeAssets[this.asset].file == null && !!this.creative.creativeAssets[this.asset].fileAdditional) {
                this.creative.creativeAssets[this.asset].isUploadValid = false;
                this.validationMessage = "Missing creative image"
                this.border = true;
            }
        }
    }

    checkDeleteUploadWarning(){
        if(this.campaign.campaignType == eAccountType.FAN365 && this.creative.creativeGroup == 1){
            let message: string = "";
            let flightCounter: number = 0;
            this.campaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.creativeGroup > 1).forEach(creative =>{
                if(creative.creativeGroup > 1 && creative.creativeTypeId == this.creative.creativeTypeId){
                    let isUploadExist = false;
                    creative.creativeAssets.filter(x => x.entityStatus == eEntityStatus.Active).forEach(ca =>{
                        if(this.originalHeight == ca.height && this.originalWidth == ca.width){
                            isUploadExist = true;
                            return;
                        }
                    });
                    if(isUploadExist){
                        let creativeGroupSuffix = creative.creativeGroup == 2 ? "nd" : creative.creativeGroup == 3 ? "rd" : "th";
                        flightCounter++;
                        if(message == "")
                            message += "The [" + this.originalWidth + "x" + this.originalHeight + "] Asset you have removed exists on the " + creative.creativeGroup + creativeGroupSuffix;
                        else
                            message += ", " + creative.creativeGroup + creativeGroupSuffix;
                    }
                }
            });
            if(message != ""){
                message += " flight.";
                this.uploadValidationMessage.emit(message);
            }
            else
                return true;
        }
        else 
            return true;
    }

    ngOnDestroy() {

    }

    getDuration(e) {
        if (!!this.dimension && !!this.fileToUpload) {
            let validDimension = !!this.dimension ? this.dimension.toString().replace(/ /g, '').replace(/\(recommended\)/g, '') : null;

            if (!!validDimension && validDimension.split(",").indexOf(e.target.videoWidth.toString() + 'x' + e.target.videoHeight.toString()) == -1) {
                this.border = true;
                this.creative.creativeAssets[this.asset].isUploadValid = false;
                this.cardItemService.updatedDataSelection(this.campaign);
                this.url = null;
                this.isPicture = false;
                this.isVideo = false;
                this.isAudio = false;
                this.originalWidth = 0;
                this.originalHeight = 0;
                this.validationMessage = "Incorrect file resolution"
            }
            else {
                this.border = false;

                this.fileTypeId = (this.campaign.campaignType == eAccountType.OAS && this.campaign.campaignInventoryItems[0].inventoryItemOAS.kindOfCreative == eCreativeType.Video) ?
                    eFileType.OASVideo :
                    (this.creative.channelId == eChannelType.Social ? eFileType.FacebookNewsFeedDynamicVideo :
                        (this.creative.creativeTypeId == eStandardCampaginChannel.In_Stream_Video ? eFileType.InStreamVideo :
                            (this.creative.creativeTypeId == eStandardCampaginChannel.Spotify_Video ? eFileType.SpotifyVideo : eFileType.NativeVideo)));
                this.saveCampaignAsset(this.fileToUpload);
                // this.url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(this.fileToUpload));
                let blob = new Blob([this.fileToUpload], { type: this.fileToUpload.type });
                const urlPattern = /^blob:http.+/;
                const url = window.URL.createObjectURL(blob);

                if (urlPattern.test(url)) {
                    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url) as SafeResourceUrl;
                }

                this.isVideo = true;
                this.originalWidth = 0;
                this.originalHeight = 0;
            }
        }
    }


    checkIsUploadEnabled() {
        if(!this.url && ((!this.isPicture && !this.isVideo && !this.isAudio) || this.border) && !this.reviewPage
        && (this.functions.checkCanChangeAsset(this.creative.creativeGroup, this.creative.flightStartDate, this.campaign.startDate, this.campaign.campaignStatusId, this.campaign.statusId, this.campaign.campaignType, this.currentUser, this.creative.isBuilt, !!this.campaign.campaignInventoryItems ? this.campaign.campaignInventoryItems[0] : null, this.maxNumberOfFlights) || 
            ((this.campaign.account.isCampaignCreation || this.campaign.account.isHubStandardCampaign) 
                && (this.campaign.campaignStatusId == eCampaignStatus.Campaign_Draft || this.campaign.campaignStatusId == eCampaignStatus.Assets_Pending 
                || this.campaign.campaignStatusId == eCampaignStatus.Assets_Declined || this.campaign.statusId == eCampaignStatus.Assets_Declined 
                || this.campaign.campaignStatusId == eCampaignStatus.Assets_Approved || this.campaign.statusId == eCampaignStatus.Assets_Approved
                || this.campaign.campaignStatusId == eCampaignStatus.Assets_Uploaded || this.campaign.statusId == eCampaignStatus.Assets_Uploaded
                || this.campaign.campaignStatusId == eCampaignStatus.Payment_Pending
                || this.campaign.campaignStatusId == eCampaignStatus.Creative_Swaps || this.campaign.statusId == eCampaignStatus.Creative_Swaps))))
            return true;
        else
            return false;
    }

    deleteAsset() {
        if(!this.reviewPage && this.functions.checkCanChangeAsset(this.creative.creativeGroup, this.creative.flightStartDate, this.campaign.startDate, this.campaign.campaignStatusId, this.campaign.statusId, this.campaign.campaignType, this.currentUser, this.creative.isBuilt, !!this.campaign.campaignInventoryItems ? this.campaign.campaignInventoryItems[0] : null, this.maxNumberOfFlights))
            this.onAssetDelete.emit(this.creativeAsset);
    }

    validateAssetWithFirstFlight(){
        let firstCreative = this.campaign.creatives.filter(x => x.entityStatus != eEntityStatus.Deleted && x.creativeGroup == 1 && x.creativeTypeId == this.creative.creativeTypeId)[0];
        let isValid = false;

        let firstCreativeAssetCount = firstCreative.creativeAssets.filter(x => x.entityStatus != eEntityStatus.Deleted && x.height == this.originalHeight && x.width == this.originalWidth).length;

        if(firstCreativeAssetCount > 0){
            isValid = true;
        }
        return isValid;
    }

}
