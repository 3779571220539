<div class="d-flex flex-column col-md-9 mx-auto p-3">
    <h2 class="first">Campaign Reporting</h2>
    <div class="d-flex justify-content-between">
        <h6 class="border-gray mb-0 ">Get all the necessary information regarding your campaign here</h6>
        <button (click)="navigateToCampaignPage()" pButton class="flat-btn" style="margin-right: -15px;" label="Back to Campaign"></button>
    </div>
</div>


<div>
    <campaign-report-assets *ngIf="!!baseCampaign && (!!baseCampaign.reportFile? !!file: true)" [file]="file" [baseCampaign]="baseCampaign"></campaign-report-assets>
    <campaign-report-manage [file]="file" [baseCampaign]="baseCampaign" class="pt-3" *ngIf="(currentUser.permissions.isEmployee || currentUser.permissions.isAdmin) && !!baseCampaign && (!!baseCampaign.reportFile? !!file: true)"></campaign-report-manage>
    <campaign-report-external-ids *ngIf="!!baseCampaign && (baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS) && (currentUser.permissions.isEmployee || currentUser.permissions.isAdmin)" 
        [dealNumber]="baseCampaign.dealId" [baseCampaignId]="baseCampaign.id" [isLearfieldCampaign]="baseCampaign.campaignType == enumAccountType.FAN365 " 
        [isOASCampaign]="baseCampaign.campaignType == enumAccountType.OAS" [procedureCampaignId]="baseCampaign.campaignJSON" (onSave)="idsSaved($event)">
    </campaign-report-external-ids>
    <campaign-report-table *ngIf="!!baseCampaign && (baseCampaign.campaignType == enumAccountType.FAN365 || baseCampaign.campaignType == enumAccountType.OAS) && (!currentUser.permissions.isClient || baseCampaign.isReportVisible)" 
        [isReportVisible]="baseCampaign.isReportVisible" [title]="baseCampaign.campaignType == enumAccountType.FAN365 ? 'Fan365' : (baseCampaign.campaignType == enumAccountType.OAS ? 'OAS' : null)" 
        [baseCampaignId]="baseCampaign.id" [baseCampaignName]="baseCampaign.name" [procedureCampaignId]="baseCampaign.campaignJSON">
    </campaign-report-table>
    <!-- <campaign-report-detail></campaign-report-detail> -->
</div>