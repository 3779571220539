import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { SelectItem, MessageService, ConfirmationService } from 'primeng/api';
import { CampaignController } from '@Workspace/_generated/services';
import { eEntityStatus, eCampaignStatus, eStandardCampaginChannel, eChannelType, eUserRole, eVideoType, eGender, eAgeTo, eAgeFromName, eIncomeToName, eIncomeFromName, eAgeToName, eAccountType, eCampaignGoal } from '@Workspace/_generated/enums';
import { Router, ActivatedRoute } from '@angular/router';
import { CartItemService } from '../../../shared-core/components/card-item/card-item.service';
import { ICreativeAssetDto, ICreativeDto, IUploadPayloadModelDto, IUpload, IDetailJson, IDemographicsDto, IGeographicTargetingJson } from '@Workspace/_generated/interfaces';
import { IBaseCampaignDto } from '@Workspace/_generated/interfaces';
import { componentFadeIn } from '../../route-animations';
import { Subscription } from 'rxjs/internal/Subscription';
import { FileUploadService } from '../../services/file-upload.service';
import { LookUps, Constants, Functions } from '@Workspace/common';
import { AuthService } from '@Workspace/auth';
import { EnumStringPipe } from '@Workspace/pipes';
import { PageLoaderService, ProgressBarService } from '@Workspace/services';
import { CampaignService } from '../../services/campaign.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import * as moment from 'moment';
import { CampaignCardDetails } from '../../../shared-core/components/campaign-card-details/campaign-card-details.component';


@Component({
    selector: 'campaign-upload-page',
    templateUrl: 'campaign-upload-page.html',
    styleUrls: ['./campaign-upload-page.scss'],
    animations: [componentFadeIn]
})
export class CampaignUploadPage implements OnInit {
    constructor(
        public campaignController: CampaignController,
        public messageService: MessageService,
        public router: Router,
        private cardItemService: CartItemService,
        private activatedRoute: ActivatedRoute,
        private fileUploadService: FileUploadService,
        private authService: AuthService,
        private progressBar: ProgressBarService,
        private pageLoader: PageLoaderService,
        private campaignService: CampaignService,
        private sanitizer: DomSanitizer,
        private confirmationService: ConfirmationService

    ) { }
    @Output() onStepTwo: EventEmitter<any> = new EventEmitter();
    @Output() onChangeStep: EventEmitter<any> = new EventEmitter();
    @Output() onBaseCampaignInit: EventEmitter<any> = new EventEmitter();
    @Input() isApprove: boolean;
    @Input() campaignDto: IBaseCampaignDto;
    @Input() reviewPage: boolean = false;
    @Input() isPaymentVisible: boolean = false;
    @Input() numberOfCreativeFlightsAmplifyFlex: number;
    @ViewChild('cardItemDetails') cardItemDetails: CampaignCardDetails;

    creativeAssetsDto: ICreativeAssetDto = {} as ICreativeAssetDto;
    campaignId: string;
    isUploadOk: boolean = true;
    isLandingUrlOk: boolean = true;
    isAssetUploaded: boolean = false;
    baseCampaign: any;
    showCards = false;
    booleanTrue: boolean = true;
    booleanFalse: boolean = false;
    ngUnsubscribe = new Subject();
    activeIndex: number = 0;
    creativesSelected: string;
    inventoryItems: string[];
    userType: any;
    subscription: Subscription;
    browserRefresh = false;
    facebookStaticCreative: ICreativeDto[];
    facebookDynamicCreative: ICreativeDto[];
    facebookStaticVideoCreative: ICreativeDto[];
    facebookDynamicVideoCreative: ICreativeDto[];
    facebookDynamicCarouselCreative: ICreativeDto[];
    standardDisplayCreative: ICreativeDto[];
    premiumDisplayCreative: ICreativeDto[];
    cartPremiumDisplayCreative: ICreativeDto[];
    postPPremiumDisplayCreative: ICreativeDto[];
    standardPremiumDisplayCreative: ICreativeDto[];
    cartDisplayBannerCreative: ICreativeDto[];
    ppuDisplayBannerCreative: ICreativeDto[];
    spotifyVideoCreative: ICreativeDto[];
    spotifyAudioCreative: ICreativeDto[];
    inStreamVideoVideoCreative: ICreativeDto[];
    googleAdWorkCreative: ICreativeDto[];
    nativeVideoCreative: ICreativeDto[];
    nativeImageCreative: ICreativeDto[];
    connectedTvCreative: ICreativeDto[];
    youtubeVideoCreative: ICreativeDto[];
    linkedinImageCreative: ICreativeDto[];
    linkedinVideoCreative: ICreativeDto[];
    linkedinCarouselCreative: ICreativeDto[];
    tikTokInFeedAdsCreative: ICreativeDto[];
    creativeAssetsForPreview: ICreativeAssetDto[] = [];
    stories: ICreativeDto[];
    instantExperience: ICreativeDto[];
    leadGeneration: ICreativeDto[];
    isPreviewOpen: boolean;
    currentUser = this.authService.userSettings;
    currentIndex: number;
    note: string;
    canSubmit: boolean = true;
    videoTypes: SelectItem[] = [];
    videoType: eVideoType[] = [];
    isLoaded: boolean;
    demographicsValues: string[] = ["", "", ""];
    geographicsValues = ["", "", "", ""];
    displayShow: boolean = false;
    facebookShow: boolean = false;
    otherShow: boolean = false;
    enumCampaignStatus = eCampaignStatus;
    enumAccountType = eAccountType;
    isWarningMessageVisible: boolean = true;
    isCampaignSucessfullySaved = new BehaviorSubject<boolean>(false);
    constants = Constants;
    numberOfFlights: number[];
    tooltipDisabled: string = '';
    creativeFlightsDates: any[] = [];
    enumUserRole = eUserRole;
    previewedCreativeIsBuilt: boolean;

    valueProgressBar: number = 0; 
    payloadFileSize: number = 0;
    isFilesUploaded: boolean = false;
    isSavedCampaign: boolean = false;
    uploadInterval: any;
    isUploadedSuccessfully: boolean = null;
    stepNavigationHelper: boolean = false;

    async ngOnInit() {
        this.isLoaded = false;

        window.addEventListener("beforeunload", (event) => {
            event.returnValue = "";
        });

        this.activatedRoute.params
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(params => {
                this.campaignId = params.slug;
            });
        this.pageLoader.showPageLoader();

        let filter = `?$filter=Id eq ${this.campaignId}`;
        await this.campaignController.GetAllCampaigns(filter).toPromise().then(async data => {
            if (this.campaignId == Constants.GuidEmpty) {
                this.baseCampaign = this.campaignDto;
                this.baseCampaign.contactId = this.currentUser.id;
            }
            if (data.totalCount > 0){
                this.baseCampaign = data.data[0];
                this.stepNavigationHelper = true;
            }
            if (!!this.campaignDto && !!this.campaignDto.creatives){
                this.baseCampaign = this.campaignDto;
                this.baseCampaign.contactId = this.currentUser.id;
            }
            this.baseCampaign.id = this.campaignId

            await this.cardItemService.data.pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(data => {
                    if (!!data && !!data.startDate && data.isEdit) {
                        this.campaignDto.endDate = data.endDate; //new Date(Date.UTC(data.endDate.getFullYear(), data.endDate.getMonth(), data.endDate.getDate()));
                        this.campaignDto.startDate = data.startDate; //new Date(Date.UTC(data.startDate.getFullYear(), data.startDate.getMonth(), data.startDate.getDate()));
                        this.baseCampaign.packageId = data.packageId;
                        this.baseCampaign.campaignGoalId = data.campaignGoalId;
                        this.baseCampaign.campaignAddOns = data.campaignAddOns;
                        this.baseCampaign.creatives = data.creatives;
                        this.baseCampaign.advertiserId = data.advertiserId;
                        this.baseCampaign.ttdadvertiserId = data.ttdAdvertiserId;
                        this.baseCampaign.dV360AdvertiserId = data.dV360AdvertiserId;
                    }
                    if (this.baseCampaign.account.isCampaignCreation) {
                        this.facebookStaticCreative = this.baseCampaign.creatives != null ? this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed) : null;
                    }
                });

            if (!!this.baseCampaign) {
                this.note = this.baseCampaign.note;
                this.isApprove = (this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Uploaded
                    || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Declined || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Approved
                    || (this.baseCampaign.campaignStatusId == eCampaignStatus.Payment_Completed && this.baseCampaign.creatives.some(x => x.creativeAssets.some(ca => !!ca.fileId)))
                    || (this.baseCampaign.account.isHubStandardCampaign && this.baseCampaign.campaignStatusId != eCampaignStatus.Assets_Pending && this.baseCampaign.campaignStatusId != eCampaignStatus.Campaign_Ended && this.baseCampaign.campaignStatusId != eCampaignStatus.Campaign_Expired && this.baseCampaign.creatives.some(x => x.creativeAssets.some(ca => ca.isValid == false || (!!ca.file && ca.isValid == null))))
                ) && this.currentUser.userRole != eUserRole.Client;

                if (this.baseCampaign.creatives.length > 0) {
                   await this.getFilesFromAzure();
                }
                else {
                    this.isLoaded = true;
                }
            }
            
            let maxNumberOfFlights = this.baseCampaign.account.isHubStandardCampaign? 1 : _.orderBy(this.baseCampaign.creatives, 'creativeGroup', 'asc')[this.baseCampaign.creatives.length-1].creativeGroup;
            this.numberOfFlights = Array(maxNumberOfFlights).fill(maxNumberOfFlights).map((x,i)=>i+1);

            if(!this.baseCampaign.account.isHubStandardCampaign){
                this.numberOfFlights.forEach(element => {
                    this.creativeFlightsDates.push({
                        creativeGroup: element,
                        flightStartDate: this.baseCampaign.creatives.filter(x => x.creativeGroup == element)[0].flightStartDate ? new Date(this.baseCampaign.creatives.filter(x => x.creativeGroup == element)[0].flightStartDate) : '',
                        flightEndDate: this.baseCampaign.creatives.filter(x => x.creativeGroup == element)[0].flightEndDate ? new Date(this.baseCampaign.creatives.filter(x => x.creativeGroup == element)[0].flightEndDate) : '',
                    
                        flightStartDateMin: '',
                        flightStartDateMax: '',
    
                        flightEndDateMin: '',
                        flightEndDateMax: ''
                    });
                });
                this.creativeFlightsDates.forEach(x => {
                    x.flightStartDateMin = this.setMinStartDate(x.creativeGroup),
                    x.flightStartDateMax = this.setMaxStartDate(x.creativeGroup),
    
                    x.flightEndDateMin = this.setMinEndDate(x.creativeGroup),
                    x.flightEndDateMax = this.setMaxEndDate(x.creativeGroup)
                });
            }


            this.pageLoader.hidePageLoader();
        },
        error => {
            this.pageLoader.hidePageLoader();
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error.text });
        });
        this.cardItemService.data
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.isUploadOk = true;
                this.isLandingUrlOk = true;
                this.isAssetUploaded = false;
                if (!!!data.creatives) return;
                data.creatives.forEach(x => {
                    x.creativeAssets.forEach(ca => {
                        if (ca.isUploadValid != null || ca.isUploadValid != undefined)
                            this.isUploadOk = this.isUploadOk && ca.isUploadValid;
                        if (ca.isLandingPageExist != null || ca.isLandingPageExist != undefined)
                            this.isLandingUrlOk = (this.isLandingUrlOk && ca.isLandingPageExist) || ca.imageUrl==null || ca.imageUrl=="";
                        if ((ca.fileId != null && ca.fileId != Constants.GuidEmpty && ca.fileId != undefined) || (ca.file != null && ca.file != undefined) || (ca.fileWrapper != null && ca.fileWrapper != undefined))
                            this.isAssetUploaded = true;
                    });
                });
            });

        this.initLists();

        if(this.baseCampaign.campaignType == eAccountType.HMP && this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Draft
            && Functions.areHmpCampaignDatesInvalid(new Date(this.baseCampaign.startDate), new Date(this.baseCampaign.endDate), this.baseCampaign.campaignGoalId)){
            this.confirmationService.confirm({
                header: 'Invalid Campaign Dates',
                key: 'invalidDates',
                message: "The campaign dates are invalid. Please return to the first step and change the campaign dates.",
                rejectVisible: false,
                acceptVisible: true,
                acceptIcon: 'arrow-left',
                acceptLabel: 'Change Dates',
                accept: () => {
                    this.confirmationService.close();
                    this.onChangeStep.emit({ activeIndex: this.activeIndex = 0, campaign: this.baseCampaign });
                }
            });  
        }
    }

    async getFilesFromAzure(){
        this.pageLoader.showPageLoader();
        await this.campaignController.GetFilesFromAzure(this.baseCampaign)
            .toPromise().then(
                result => {
                    this.baseCampaign = result;
                    this.baseCampaign.creatives.forEach(x => {
                        if (x.detailJson == null) {
                            x.detailJson = {} as IDetailJson;
                            x.detailJson.headline = '';
                            x.detailJson.bodyText = [];
                            x.detailJson.bodyText.push('');
                            x.detailJson.linkDescription = '';
                            x.detailJson.landingPageUrl = '';
                            x.detailJson.facebookPageUrl = '';
                            x.detailJson.campaignTitle = '';
                            x.detailJson.advertiser = '';
                            x.detailJson.companyName = '';
                            x.detailJson.shortTitle = '';
                            x.detailJson.longTitle = '';
                            x.detailJson.shortDescription = '';
                            x.detailJson.longDescription = '';
                            x.detailJson.callToAction = '';
                            x.detailJson.headline2 = '';
                            x.detailJson.description = '';
                            x.detailJson.path1 = '';
                            x.detailJson.path2 = '';
                            x.detailJson.displayUrl = '';
                            x.detailJson.adGroup = '';
                            x.detailJson.postText = '';
                            x.detailJson.privacyPolicy = '';
                            x.detailJson.customDisclaimer = '';
                            x.detailJson.copyOption1 = [];
                            x.detailJson.copyOption1.push('');
                            x.detailJson.copyOption2 = '';
                            x.detailJson.buttonText = '';
                            x.detailJson.destinationUrl = '';
                            x.detailJson.introductoryText = '';
                            x.detailJson.appOrBrandName = '';
                            x.detailJson.youTubeUrlToVideo = '';
                            x.detailJson.youTubeUrlToVideo = '';
                            x.detailJson.clientYoutubeChannelUrl = '';
                            x.detailJson.clientYoutubeEmail = '';
                        }
                    });

                    this.standardDisplayCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Standard_Display_Banner);
                    this.premiumDisplayCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner);
                    this.standardPremiumDisplayCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner && x.parentCreativeTypeId == eStandardCampaginChannel.Standard_Display_Banner);
                    this.cartPremiumDisplayCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner && x.parentCreativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner);
                    this.postPPremiumDisplayCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Premium_Display_Banner && x.parentCreativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner);
                    this.cartDisplayBannerCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Abandoned_Cart_Display_Banner);
                    this.ppuDisplayBannerCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Display && x.creativeTypeId == eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner);

                    this.facebookStaticCreative = this.baseCampaign.creatives != null ? this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed) : null;
                    this.facebookDynamicCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed);
                    this.facebookStaticVideoCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed_Video);
                    this.facebookDynamicVideoCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video);
                    this.facebookDynamicCarouselCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel);
                    this.stories = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Stories);
                    this.leadGeneration = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Lead_Generation);
                    this.instantExperience = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Instant_Experience);

                    if(this.baseCampaign.campaignType == eAccountType.HS){
                        this.linkedinImageCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.LinkedIn_News_Feed_Image);
                        this.linkedinVideoCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.LinkedIn_News_Feed_Video);
                        this.linkedinCarouselCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.LinkedIn_Dynamic_News_Feed_Carousel);
                        this.tikTokInFeedAdsCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.TikTok_In_Feed_Ads);
                        this.youtubeVideoCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.YouTube_Video);
                    }
                    
                    this.spotifyVideoCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Spotify_Video);
                    this.spotifyAudioCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Spotify_Audio);
                    this.inStreamVideoVideoCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.In_Stream_Video);

                    this.googleAdWorkCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Google_AdWords);
                    this.nativeVideoCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Native_Video);
                    this.nativeImageCreative = this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.Native_Image);
                    this.connectedTvCreative = this.baseCampaign.creatives.filter(x => x.entityStatus == eEntityStatus.Active && x.channelId == eChannelType.Other && x.creativeTypeId == eStandardCampaginChannel.ConnectedTv);
                    
                    this.baseCampaign.creatives.forEach(x => {
                        if (!!x.creativeAssets)
                            x.creativeAssets.forEach(ca => {
                                if (!!ca.file && !!ca.file.urlWithToken) {
                                    this.creativeAssetsForPreview.push(ca);
                                }
                            });
                    });
                    this.baseCampaign.isEdit = false;
                    this.cardItemService.updatedDataSelection(this.baseCampaign);
                    this.showCards = true;
                    this.isLoaded = true;
                    this.pageLoader.hidePageLoader();
                });
    }
    checkIsDisabled() {
        if(this.stepNavigationHelper && this.baseCampaign.account.isCampaignCreation){
            this.onBaseCampaignInit.emit(this.baseCampaign);
            this.stepNavigationHelper = false;
        }
        var regex = /^[a-zA-Z0-9]+[^.\n]*[.][^.\n]+([.][^.\n]+)*$/;
        let isDetailPopulate = false;
        this.baseCampaign.creatives.forEach(x => {
            if (!!x.detailJson && x.detailJson != null &&
                ((!!x.detailJson.headline && x.detailJson.headline != '') ||
                    (!!x.detailJson.bodyText && !!x.detailJson.bodyText[0] && x.detailJson.bodyText[0] != '') ||
                    (!!x.detailJson.linkDescription && x.detailJson.linkDescription != '') ||
                    (!!x.detailJson.landingPageUrl && x.detailJson.landingPageUrl != '') ||
                    (!!x.detailJson.facebookPageUrl && x.detailJson.facebookPageUrl != '') ||
                    (!!x.detailJson.campaignTitle && x.detailJson.campaignTitle != '') ||
                    (!!x.detailJson.advertiser && x.detailJson.advertiser != '') ||
                    (!!x.detailJson.companyName && x.detailJson.companyName != '') ||
                    (!!x.detailJson.shortTitle && x.detailJson.shortTitle != '') ||
                    (!!x.detailJson.longTitle && x.detailJson.longTitle != '') ||
                    (!!x.detailJson.shortDescription && x.detailJson.shortDescription != '') ||
                    (!!x.detailJson.longDescription && x.detailJson.longDescription != '') ||
                    (!!x.detailJson.callToAction && x.detailJson.callToAction != '') ||
                    (!!x.detailJson.headline2 && x.detailJson.headline2 != '') ||
                    (!!x.detailJson.description && x.detailJson.description != '') ||
                    (!!x.detailJson.path1 && x.detailJson.path1 != '') ||
                    (!!x.detailJson.path2 && x.detailJson.path2 != '') ||
                    (!!x.detailJson.displayUrl && x.detailJson.displayUrl != '') ||
                    (!!x.detailJson.adGroup && x.detailJson.adGroup != '') ||
                    (!!x.detailJson.postText && x.detailJson.postText != '') ||
                    (!!x.detailJson.privacyPolicy && x.detailJson.privacyPolicy != '') ||
                    (!!x.detailJson.customDisclaimer && x.detailJson.customDisclaimer != '') ||
                    (!!x.detailJson.copyOption2 && x.detailJson.copyOption2 != '') ||
                    (!!x.detailJson.buttonText && x.detailJson.buttonText != '') ||
                    (!!x.detailJson.destinationUrl && x.detailJson.destinationUrl != '') ||
                    (!!x.detailJson.copyOption1 && !!x.detailJson.copyOption1[0] && x.detailJson.copyOption1[0] != '') ||
                    (!!x.detailJson.videoType && x.detailJson.videoType != 0))) {
                isDetailPopulate = true;
            }
        });

        if (!this.currentUser.permissions.isClient && this.baseCampaign.campaignStatusId != eCampaignStatus.Campaign_Ended && this.baseCampaign.campaignStatusId != eCampaignStatus.Campaign_Expired &&
            this.baseCampaign.statusId != eCampaignStatus.Sent_to_API_Build && this.baseCampaign.campaignStatusId != eCampaignStatus.Payment_Pending &&
            this.baseCampaign.campaignStatusId != eCampaignStatus.Payment_Completed && this.isLandingUrlOk) {
            this.tooltipDisabled = '';
            return false;
            
        }

        else if (this.baseCampaign.campaignType == eAccountType.Social && this.baseCampaign.creatives[0].creativeTypeId != eStandardCampaginChannel.Paid_support && 
            this.baseCampaign.creatives[0].creativeTypeId != eStandardCampaginChannel.Flexible_Campaign &&  !!!this.baseCampaign.creatives[0].creativeAssets[0].file) {
            this.tooltipDisabled = 'Please upload Sponsor Logo before proceeding.';
            return true;
        }

        else if ((this.baseCampaign.account.isCampaignCreation && this.baseCampaign.creatives.some(x => x.creativeGroup > 1)) && this.baseCampaign.creatives.filter(x => x.creativeTypeId != eStandardCampaginChannel.Facebook_Static_News_Feed_Video).some(cr => !cr.creativeAssets.some(ca => !!ca.file))) {
            this.tooltipDisabled = 'Please upload at least one asset per creative flight';
            return true;
        }

        else if(this.baseCampaign.creatives.some( x => x.creativeGroup > 1) &&  
                 this.baseCampaign.creatives.filter(x => x.creativeTypeId != eStandardCampaginChannel.Abandoned_Cart_Display_Banner && x.creativeTypeId != eStandardCampaginChannel.Post_Purchase_Upsell_Display_Banner && x.creativeTypeId != eStandardCampaginChannel.Premium_Display_Banner)
                 .some(cr => cr.flightEndDate == null || cr.flightEndDate == undefined || cr.flightStartDate == null || cr.flightStartDate == undefined)){
                   this.tooltipDisabled = 'Please enter dates per creative flight';
                   return true;
                 }


        else if (!this.isLandingUrlOk || (this.isAssetUploaded == false && isDetailPopulate == false) ||
                    (this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Ended || this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Expired
                        || ((this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Live || this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Paused 
                                || this.baseCampaign.statusId == eCampaignStatus.Sent_to_API_Build) && !this.baseCampaign.account.isHubStandardCampaign)
                    )
                    || (this.baseCampaign.creatives.some(x=>x.creativeTypeId==eStandardCampaginChannel.In_Stream_Video && (x.detailJson.landingPageUrl=='' || x.detailJson.landingPageUrl==null || x.detailJson.landingPageUrl==undefined || x.detailJson.landingPageUrl.match(regex)==null) && x.creativeAssets.some(x=>x.file!=null)))
                ){
                    this.tooltipDisabled = 'Missing Landing Page URL for uploaded asset!';
                    return true;
                }
        else if(this.baseCampaign.campaignType == eAccountType.HMP && this.baseCampaign.campaignStatusId == eCampaignStatus.Campaign_Draft 
            && Functions.areHmpCampaignDatesInvalid(new Date(this.baseCampaign.startDate), new Date(this.baseCampaign.endDate), this.baseCampaign.campaignGoalId))
            {
                this.tooltipDisabled = 'The campaign dates are invalid!';
                return true;
            }
        else if(!!this.cardItemDetails && !this.cardItemDetails.costsTableValidation){
            this.tooltipDisabled = 'Something went wrong with costs calculation.';
            return true;
        }
        else
        {
            this.tooltipDisabled = '';
            return false;
        }
            
    }
    initLists() {
        this.videoTypes = LookUps.getVideoTypeLu();
        this.videoTypes.unshift({ value: 0, label: "-" });
    }
    checkForCreatives() {
        if (this.facebookStaticCreative || this.facebookDynamicCreative || this.facebookDynamicVideoCreative || this.facebookDynamicCarouselCreative || this.facebookStaticVideoCreative
            || this.standardDisplayCreative || this.premiumDisplayCreative || this.cartDisplayBannerCreative || this.ppuDisplayBannerCreative
            || this.spotifyVideoCreative || this.spotifyAudioCreative || this.inStreamVideoVideoCreative || this.googleAdWorkCreative
            || this.nativeVideoCreative || this.nativeImageCreative || this.connectedTvCreative || this.stories || this.leadGeneration 
            || this.instantExperience || this.youtubeVideoCreative
            || this.linkedinImageCreative || this.linkedinVideoCreative || this.linkedinCarouselCreative || this.tikTokInFeedAdsCreative)
            return true;
        else
            return false;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        window.removeEventListener("beforeunload", (event) => {
            event.returnValue = "";
        });
        this.progressBar.hideProgressBar();
        this.isFilesUploaded = true;
    }
    addCreative(creativeGroup: number) {
        if (this.facebookStaticCreativeLengthCheck(creativeGroup) <= 4) {
            let creativesDto = {} as ICreativeDto;
            creativesDto.channelId = eChannelType.Social;
            creativesDto.creativeTypeId = eStandardCampaginChannel.Facebook_Static_News_Feed;
            creativesDto.id = Constants.GuidEmpty;
            creativesDto.quantity = 1;
            creativesDto.creativeGroup = creativeGroup;
            creativesDto.creativeAssets = [];
            creativesDto.isBuilt = false;
            creativesDto.name = new EnumStringPipe().transform(eStandardCampaginChannel.Facebook_Static_News_Feed, eStandardCampaginChannel);
            creativesDto.detailJson = {} as IDetailJson;
            creativesDto.detailJson.headline = '';
            creativesDto.detailJson.bodyText = [];
            creativesDto.detailJson.bodyText.push('');
            creativesDto.detailJson.linkDescription = '';
            creativesDto.detailJson.landingPageUrl = '';
            creativesDto.detailJson.facebookPageUrl = '';
            creativesDto.detailJson.callToAction = '';
            creativesDto.flightStartDate = new Date(this.creativeFlightsDates.filter( x => x.creativeGroup == creativeGroup)[0].flightStartDate);
            creativesDto.flightEndDate = new Date(this.creativeFlightsDates.filter( x => x.creativeGroup == creativeGroup)[0].flightEndDate);
            for (var element = 0; element < 2; element++) {
                let creativeAssetDto = {} as ICreativeAssetDto;
                creativeAssetDto.id = Constants.GuidEmpty;
                creativeAssetDto.width = 0;
                creativeAssetDto.height = 0;
                creativeAssetDto.createdOn = new Date();
                creativesDto.creativeAssets.push(creativeAssetDto);
            }
            this.baseCampaign.creatives.push(creativesDto);
            this.facebookStaticCreative = this.baseCampaign.creatives != null ? this.baseCampaign.creatives.filter(x => x.channelId == eChannelType.Social && x.creativeTypeId == eStandardCampaginChannel.Facebook_Static_News_Feed) : null;
        }
    }
    save() {
        this.isWarningMessageVisible = false;
        let redirectToSuccess = false;
        if (this.isApprove) {
            if (!this.baseCampaign.creatives.some(x => x.creativeAssets.some(a => a.isValid == false || (!!a.file && a.isValid == null))) && this.baseCampaign.creatives.some(x => x.creativeAssets.some(a => a.isValid == true && !!a.file))) {
                if ((this.baseCampaign.account.isHubStandardCampaign || this.baseCampaign.account.isCampaignCreation)
                         && this.baseCampaign.campaignStatusId != eCampaignStatus.Assets_Uploaded) {
                    this.baseCampaign.campaignStatusId = this.baseCampaign.campaignStatusId;
                } else {
                    this.baseCampaign.campaignStatusId = eCampaignStatus.Assets_Approved;
                }
            }
            else if (this.baseCampaign.creatives.some(x => x.creativeAssets.some(a => a.isValid == false))) {
                if (this.baseCampaign.account.isHubStandardCampaign && this.baseCampaign.campaignStatusId != eCampaignStatus.Assets_Uploaded) {
                    this.baseCampaign.campaignStatusId = this.baseCampaign.campaignStatusId;
                } else {
                    this.baseCampaign.campaignStatusId = eCampaignStatus.Assets_Declined;
                }
            }
        }

        if (!this.baseCampaign.creatives.some(x => x.creativeAssets.some(a => !!a.file)))
            this.baseCampaign.campaignStatusId = eCampaignStatus.Assets_Pending;

        this.baseCampaign.note = this.note;
        if (this.baseCampaign.account.isHubStandardCampaign) {
            // if (!!this.baseCampaign.startDate) {
            //     //this.baseCampaign.startDate = new Date(this.baseCampaign.startDate);

            //     this.baseCampaign.startDate = new Date(Date.UTC(this.baseCampaign.startDate.getFullYear(), this.baseCampaign.startDate.getMonth(), this.baseCampaign.startDate.getDate(), this.baseCampaign.startDate.getHours(), this.baseCampaign.startDate.getMinutes(), this.baseCampaign.startDate.getSeconds()));
            // }
            // if (!!this.baseCampaign.endDate) {
            //     //this.baseCampaign.endDate = new Date(this.baseCampaign.endDate);

            //     this.baseCampaign.endDate = new Date(Date.UTC(this.baseCampaign.endDate.getFullYear(), this.baseCampaign.endDate.getMonth(), this.baseCampaign.endDate.getDate(), this.baseCampaign.endDate.getHours(), this.baseCampaign.endDate.getMinutes(), this.baseCampaign.endDate.getSeconds()));
            // }
            // if (!!this.baseCampaign.dueDate) {
            //     //this.baseCampaign.endDate = new Date(this.baseCampaign.endDate);

            //     this.baseCampaign.dueDate = new Date(Date.UTC(this.baseCampaign.dueDate.getFullYear(), this.baseCampaign.dueDate.getMonth(), this.baseCampaign.dueDate.getDate(), this.baseCampaign.dueDate.getHours(), this.baseCampaign.dueDate.getMinutes(), this.baseCampaign.dueDate.getSeconds()));
            // }
        }

        // else {
            this.saveCampaign(false, redirectToSuccess);
        // }
    }

    saveCampaign(isFromPayment?: boolean, redirectToSuccess?: boolean) {
        this.pageLoader.showPageLoader();
        if ((this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Pending || this.baseCampaign.campaignStatusId == eCampaignStatus.Assets_Declined) && (this.reviewPage == true || isFromPayment)) 
        {
            this.baseCampaign.campaignStatusId = eCampaignStatus.Assets_Uploaded;
            if (this.currentUser.permissions.isClient) {
                redirectToSuccess = true;
            }
        }

        if (!this.baseCampaign.creatives.some(x => x.creativeAssets.some(a => !!a.file))) {
            this.baseCampaign.campaignStatusId = eCampaignStatus.Assets_Pending;
        }
        if (isFromPayment) {
            this.baseCampaign.campaignStatusId = eCampaignStatus.Payment_Pending;
        }

        this.baseCampaign.creatives.forEach(c => {
            c.creativeAssets.forEach(ca => {
                if (!!ca.file) {
                    ca.file.urlWithToken = null;
                }
            });
            if(this.baseCampaign.campaignType == eAccountType.HMP){
                Functions.dateTimeZoneConversion(c.flightStartDate);
                Functions.dateTimeZoneConversion(c.flightEndDate);
            }
        });

        this.campaignController
            .SaveCampaign(this.baseCampaign)
            .subscribe(data => {
                if(Functions.isDatesEqual(new Date(data.startDate),new Date(this.baseCampaign.startDate)) && Functions.isDatesEqual(new Date(data.endDate),new Date(this.baseCampaign.endDate))
                    && data.note == this.baseCampaign.note && data.creatives.filter(x=>x.entityStatus==eEntityStatus.Active).length == this.baseCampaign.creatives.length){
                        this.isSavedCampaign = true;
                }
                else{
                    this.isSavedCampaign = false;
                }

                this.baseCampaign.id = data.id;
                this.baseCampaign.entityStatus = data.entityStatus;
                this.baseCampaign.creatives.forEach((x, index) => {
                    data.creatives.filter(x => x.entityStatus == eEntityStatus.Active).forEach((d, dindex) => {
                        if (x.id == Constants.GuidEmpty && x.creativeTypeId == d.creativeTypeId && ((index == dindex && data.account.isCampaignCreation) || !data.account.isCampaignCreation)) {
                            x.id = d.id;
                            if (x.channelId == eChannelType.Display) {
                                x.creativeAssets.forEach(xca => {
                                    d.creativeAssets.forEach(dca => {
                                        if (xca.id == Constants.GuidEmpty && xca.height == dca.height && xca.width == dca.width) {
                                            xca.id = dca.id;
                                            xca.creativeId = dca.creativeId;
                                        }
                                    });
                                });
                            }

                            else if (x.channelId == eChannelType.Social) {
                                for (let i = 0; i < x.creativeAssets.length; i++) {
                                    x.creativeAssets[i].id = d.creativeAssets[i].id;
                                    x.creativeAssets[i].creativeId = d.creativeAssets[i].creativeId;
                                }
                            }

                            else if (x.channelId == eChannelType.Other) {
                                for (let i = 0; i < x.creativeAssets.length; i++) {
                                    x.creativeAssets[i].id = d.creativeAssets[i].id;
                                    x.creativeAssets[i].creativeId = d.creativeAssets[i].creativeId;
                                }
                            }
                        }
                    });
                });

                this.baseCampaign.creatives.forEach(x => {
                    if (x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel || x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed ||
                        x.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Video || x.creativeTypeId == eStandardCampaginChannel.Instant_Experience) {
                        let newAssets = x.creativeAssets.filter(nAssets => nAssets.id == Constants.GuidEmpty);

                        data.creatives.filter(d => d.id == x.id || (x.id == Constants.GuidEmpty && d.creativeGroup == x.creativeGroup && d.creativeTypeId == x.creativeTypeId)).forEach(d => {
                            if (d.creativeTypeId == eStandardCampaginChannel.Facebook_Dynamic_News_Feed_Carousel) {
                                let addedAssets = d.creativeAssets.filter(c => !x.creativeAssets.some(a => a.id == c.id) && c.entityStatus != eEntityStatus.Deleted);
                                newAssets.forEach(na => {
                                    let existing = addedAssets.find(e => JSON.stringify(e.detailJson).toLowerCase() == JSON.stringify(na.detailJson).toLowerCase() && !e.isChanged);
                                    if (!!existing) {
                                        existing.isChanged = true;
                                        na.id = existing.id;
                                    }
                                });
                            }
                            else {
                                let addedAssets = d.creativeAssets.filter(c => c.entityStatus != eEntityStatus.Deleted && !x.creativeAssets.some(a => a.id == c.id));
                                if (!!addedAssets && addedAssets.length > 0 && !!newAssets && newAssets.length > 0) {
                                    for (let i = 0; i < newAssets.length; i++) {
                                        newAssets[i].id = addedAssets[i].id;
                                    }
                                }
                            }
                        }); 
                    }
                });

                if (this.isApprove && !this.baseCampaign.creatives.some(x => x.creativeAssets.some(ca => ca.isChanged)) && !this.currentUser.permissions.isClient) {
                    this.pageLoader.hidePageLoader();
                    if(this.isSavedCampaign){
                        this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Campaign Is Saved As Draft.' })
                    }
                    else{
                        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign is not saved.' });
                    }
                    setTimeout(() => this.router.navigate(['/mogo']), 1000);
                }
                else if (redirectToSuccess && !this.baseCampaign.creatives.some(x => x.creativeAssets.some(ca => ca.isChanged))) {
                    this.pageLoader.hidePageLoader();

                    if (this.currentUser.permissions.isHMPClient && this.reviewPage && !this.baseCampaign.isCampaignSetAsPaid) {

                        this.onChangeStep.emit({ activeIndex: this.activeIndex = 4, campaign: this.baseCampaign });
                        this.isPaymentVisible = true;
                        this.reviewPage = false;
                    }
                    else{
                        if(this.isSavedCampaign){
                            this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Campaign Is Saved As Draft.' });
                        }
                        else{
                            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign is not saved.' });
                        }
                        setTimeout(() => this.router.navigate(['/campaign/' + this.baseCampaign.id + '/campaign-success-card']), 1000);
                    }

                }
                else {
                    const blobToFile = (blob: Blob, fileName: string): File => {
                        let file: any = blob;
                        file.lastModifiedDate = Date.now();
                        file.name = fileName;

                        return <File>file;
                    }
                    let payload = { uploads: [] } as IUploadPayloadModelDto;
                    this.baseCampaign.creatives.forEach(creative => {
                        creative.creativeAssets.filter(x=>x.entityStatus != eEntityStatus.Deleted).forEach(asset => {
                            if (this.reviewPage || !this.currentUser.permissions.isClient)
                                asset.isFromReviewPage = true;
                            if (asset.fileWrapper) {
                                let blob = new Blob([asset.fileWrapper]);
                                let file = blobToFile(blob, asset.fileName);
                                let upload = { detailsJson: JSON.stringify(asset), campaignId: this.baseCampaign.id, fileTypeId: asset.file.fileTypeId, fileExtension: asset.fileType, fileName: asset.id, pathName: `${this.baseCampaign.id}/${creative.id}`, file: file } as IUpload;
                                payload.uploads.push(upload);
                            }
                            if (asset.fileWrapperAdditional) {
                                let blob = new Blob([asset.fileWrapperAdditional]);
                                let file = blobToFile(blob, asset.fileName);
                                let upload = { detailsJson: JSON.stringify(asset), campaignId: this.baseCampaign.id, fileTypeId: asset.fileAdditional.fileTypeId, fileExtension: asset.fileAdditional.fileType, fileName: asset.id, pathName: `${this.baseCampaign.id}/${creative.id}`, file: file } as IUpload;
                                payload.uploads.push(upload);
                            }
                            if (asset.isDeleted){
                                let upload = { detailsJson: JSON.stringify(asset), campaignId: this.baseCampaign.id, fileName: asset.id, pathName: `${this.baseCampaign.id}/${creative.id}`} as IUpload;
                                payload.uploads.push(upload);
                            }
                        });
                    });

                   
                    payload.uploads.forEach(x => {
                        this.payloadFileSize += !!x.file ? x.file.size : 0;
                    });

                    var counter = 0;
                    var uploadSpeed = 187500;

                      if (
                          this.payloadFileSize / uploadSpeed > 0 &&
                          this.payloadFileSize / uploadSpeed < 25
                      ) {
                          this.uploadInterval = setInterval(() => {
                              if (this.isFilesUploaded) {
                                  this.progressBar.showProgressBar(100);
                              } else {
                                  counter = counter + 10;
                                  this.valueProgressBar = counter;
                                  this.progressBar.showProgressBar(
                                      this.valueProgressBar
                                  );

                                  if (counter >= 90) {
                                      counter = 89;
                                  }
                              }
                          }, ((this.payloadFileSize / uploadSpeed) * 1000 * 10) / 100);
                      } else if (this.payloadFileSize / uploadSpeed >= 25) {
                          this.uploadInterval = setInterval(() => {
                              if (this.isFilesUploaded) {
                                this.progressBar.showProgressBar(100);

                              } else {
                                  this.valueProgressBar = counter++;

                                  this.progressBar.showProgressBar(
                                      this.valueProgressBar
                                  );

                                  if (counter >= 100) {
                                      counter = 99;
                                      // this.progressBar.hideProgressBar();
                                      // clearInterval(interval);
                                  }
                              }
                          }, ((this.payloadFileSize / uploadSpeed) * 1000) / 100);
                      }



                    if (payload.uploads.length > 0) {
                        this.fileUploadService.UploadCreativeAssets(payload, this.reviewPage).subscribe(
                            (res: any) => {
                                if (!!res && res.ok) {
                                    this.isFilesUploaded = true;
                                    
                                    this.progressBar.showProgressBar(100);
                                    setTimeout(() => {
                                        this.progressBar.hideProgressBar();
                                        clearInterval(this.uploadInterval);
                                        this.valueProgressBar = 0;
                                        this.pageLoader.hidePageLoader();
                                        this.isFilesUploaded = false;

                                        this.baseCampaign.creatives.forEach(creative => {
                                            creative.creativeAssets.forEach(x => {    
                                                if(x.fileName != null || (x.file != null && x.file.fileName != null)){
                                                    this.isUploadedSuccessfully = false;
                                                    res.body.uploads.forEach(element => {
        
                                                        var asset = JSON.parse(element.detailsJson);
        
                                                        if(asset!=null && asset.FileName.split('.')[0] == x.id 
                                                            && (asset.SizeInBytes == x.file.sizeInBytes || asset.SizeInBytes == x.fileSize)
                                                            && (asset.Description == x.file.description || asset.Description == x.fileName)){
                                                            this.isUploadedSuccessfully = true;
                                                        }
                                                    });
                                                }
                                            });
                                        });
                                        let numberOfDeletedAssets: number = 0;
                                        this.baseCampaign.creatives.forEach(x => {numberOfDeletedAssets += x.creativeAssets.filter(y=>y.isDeleted).length;});
                                        this.isUploadedSuccessfully = this.isUploadedSuccessfully != null ? this.isUploadedSuccessfully && numberOfDeletedAssets == res.body.uploads.filter(z=> z.campaignId == Constants.GuidEmpty).length
                                            : numberOfDeletedAssets == res.body.uploads.filter(z=> z.campaignId == Constants.GuidEmpty).length;
    
                                        if(!this.isUploadedSuccessfully){
                                            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Something wrong with uploading assets.' });
                                        }


                                        if ((this.reviewPage || !isFromPayment) && this.currentUser.permissions.isClient) {
                                            if (this.currentUser.permissions.isHMPClient && this.reviewPage && !this.baseCampaign.isCampaignSetAsPaid) {
                                                this.onChangeStep.emit({ activeIndex: this.activeIndex = 4, campaign: this.baseCampaign });
                                                this.isPaymentVisible = true;
                                                this.reviewPage = false;
                                            }
                                            else if (redirectToSuccess) {
                                                if(this.isSavedCampaign){
                                                    this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Campaign Is Saved As Draft.' })
                                                }
                                                else{
                                                    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign is not saved.' });
                                                }
                                                setTimeout(() => this.router.navigate(['/campaign/' + this.baseCampaign.id + '/campaign-success-card']), 2000);
                                            } else {
                                                if(this.isSavedCampaign){
                                                    this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Campaign Is Saved As Draft.' })
                                                }
                                                else{
                                                    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign is not saved.' });
                                                }
                                                setTimeout(() => this.router.navigate(['/mogo']), 2000);
                                            }
                                        }
                                        else {
                                            if (this.currentUser.permissions.isHMPClient && this.reviewPage && !this.baseCampaign.isCampaignSetAsPaid) {
                                                this.onChangeStep.emit({ activeIndex: this.activeIndex = 4, campaign: this.baseCampaign });
                                                this.isPaymentVisible = true;
                                                this.reviewPage = false;
                                            }
                                            else {
                                                if(this.isSavedCampaign){
                                                    this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Campaign Is Saved As Draft.' })
                                                }
                                                else{
                                                    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign is not saved.' });
                                                }
                                                setTimeout(() => this.router.navigate(['/mogo']), 2000);
                                            }
                                        }
                                    }, 2000);
                                }
                            },
                            err => {
                                this.pageLoader.hidePageLoader();
                                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Something wrong with uploading assets.' });

                                return '';
                            }
                        );
                    }
                    else {
                        this.pageLoader.hidePageLoader();
                        if (this.currentUser.permissions.isHMPClient && this.reviewPage && !this.baseCampaign.isCampaignSetAsPaid) {
                            this.onChangeStep.emit({ activeIndex: this.activeIndex = 4, campaign: this.baseCampaign });
                            this.isPaymentVisible = true;
                            this.reviewPage = false;
                        }
                        else {
                            if(this.isSavedCampaign){
                                this.messageService.add({ severity: 'success', summary: 'Successfully Saved', detail: 'Campaign Is Saved As Draft.' })
                            }
                            else{
                                this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Campaign is not saved.' });
                            }
                            setTimeout(() => this.router.navigate(['/mogo']), 2000);
                        }
                    }
                }
            },
            error =>{
                for (const property in error.error.Errors) {
                    error.error.Errors[property].forEach(e => {
                        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: e });
                    });
                }
            });
    }
    goToLastStep(event) {
        this.onChangeStep.emit({ activeIndex: this.activeIndex = 5, campaign: event.baseCampaign, paymentData: event });
    }

    getSubmittButtonLabel() {
        if (this.reviewPage) {
            if (this.currentUser.permissions.isHMPClient && !this.baseCampaign.isCampaignSetAsPaid) {
                return 'Payment'
            } else {
                return 'Submit'
            }
        }
        else {
            return 'Save & exit to Home'
        }
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        //console.log('Back button pressed');
        return event.returnValue = 'Your changes will not be saved';
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isWarningMessageVisible;//window.confirm('Do you really want to cancel?');
        // returning true will navigate without confirmation
        // returning false will show a confirm dialog before navigating away
    }

    @HostListener('window:onunload')
    canCloseWindow(): Observable<boolean> | boolean {
        return;//window.confirm('Do you really want to cancel?');
        // returning true will navigate without confirmation
        // returning false will show a confirm dialog before navigating away
    }

    openPreview(event) {
        this.previewedCreativeIsBuilt = this.baseCampaign.creatives.find(c => c.creativeAssets.some(ca => ca.id == event.id) == true)?.isBuilt;
        this.creativeAssetsForPreview = [];
        if (!this.creativeAssetsForPreview || !this.creativeAssetsForPreview.some(x => x.id == event.id)) 
        {
            this.baseCampaign.creatives.forEach(c => c.creativeAssets.forEach(ca => {
                if (!!ca.file && !this.creativeAssetsForPreview.some(a => a.file == ca.file)) {
                    if (!ca.file.urlWithToken)
                        ca.file.urlWithToken = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(ca.fileWrapper));
                    this.creativeAssetsForPreview.push(ca);
                }
            }))
        }
        this.currentIndex = this.creativeAssetsForPreview.findIndex(x => x.id == event.id);
        this.isPreviewOpen = true;
    }

    showHideDialog(event: any) {
        this.isPreviewOpen = event.visible;
    }

    setGeographicsCardText(event: IGeographicTargetingJson) {
        if (event == null) return;
        this.geographicsValues[0] = !!event.cities && event.cities.length > 0 ? event.cities.map(x => x.name).join(";") : "";
        this.geographicsValues[1] = !!event.dmaCodes && event.dmaCodes.length > 0 ? event.dmaCodes.map(x => x.name).join(";") : "";
        this.geographicsValues[2] = !!event.states && event.states.length > 0 ? event.states.map(x => x.name).join(";") : "";
        this.geographicsValues[3] = !!event.zipCodes && event.zipCodes.length > 0 ? event.zipCodes.map(x => x.name).join(";") : "";
    }

    setDemographicsCardText(event: IDemographicsDto) {
        if (!!event && !!event.genderList) { // && event.genderList != 0
            const genderStr: string[] = [];
            let i = 0;
            let gender: number;
            while (eGender[(gender = 1 << i++)]) {
                if (event.genderList & gender) {
                    genderStr.push(eGender[gender]);
                }
            }
            this.demographicsValues[0] = genderStr.join(";");
        } else {
            this.demographicsValues[0] = "";
        }

        if (!!event && !!event.ageRange && event.ageRange.ageFrom != 0 && event.ageRange.ageTo != 0) {
            if (event.ageRange.ageTo == eAgeTo.Plus) {
                this.demographicsValues[1] = eAgeFromName.get(event.ageRange.ageFrom).name + " " + eAgeToName.get(event.ageRange.ageTo).name;
            }

            else {
                this.demographicsValues[1] = eAgeFromName.get(event.ageRange.ageFrom).name + ' - ' + eAgeToName.get(event.ageRange.ageTo).name;
            }
        } else {
            this.demographicsValues[1] = "";
        }
        if (!!event && event.incomeRange != null && event.incomeRange.from != 0 && event.incomeRange.to != 0) {
            this.demographicsValues[2] = eIncomeFromName.get(event.incomeRange.from).name + ' - ' + eIncomeToName.get(event.incomeRange.to).name;
        } else {
            this.demographicsValues[2] = "";
        }
    }

    next() {
        this.setDemographicsCardText(this.baseCampaign.demographics);
        this.setGeographicsCardText(this.baseCampaign.geographicTargetingJson);
        this.reviewPage = true;
        this.onChangeStep.emit({ activeIndex: this.activeIndex = 3, campaign: this.baseCampaign });
        document.getElementById("uploadPage").scrollIntoView();
    }


    back() {
        if (this.reviewPage) {
            this.reviewPage = false;
            this.onChangeStep.emit({ activeIndex: this.activeIndex = 2, campaign: this.baseCampaign });
        }
        else
            this.onChangeStep.emit({ activeIndex: this.activeIndex = 1, campaign: this.baseCampaign });
    }
    backFromPayment() {
        this.reviewPage = true;
        this.isPaymentVisible = false;
        this.onChangeStep.emit({ activeIndex: this.activeIndex = 3, campaign: this.baseCampaign });
        if (this.baseCampaign.creatives.length > 0) {
            this.getFilesFromAzure();
         }
    }
    cancel() {
        this.messageService.add({ severity: 'info', summary: 'Info Message', detail: 'Now, you will be redirected to Home Page.' });
        setTimeout(
            () => {
                var gridId = 3; // HMP users
                this.campaignService.setGridIdId(gridId);
                this.router.navigate(['mogo']),
                    1000
            }
        );
    }
    checkisAdditionalTargetingVisible() {
        return this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.interestTargeting : 
                                                                    (x.inventoryItemNew != null ? x.inventoryItemNew.interestTargeting : false ));
    }
    checkIsSiteVisitTrackingVisible() {
        return this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.siteVisitTracking : 
                                                                    (x.inventoryItemNew != null ? x.inventoryItemNew.siteVisitTracking : false));
    }

    checkIsConversionTrackingVisible() {
        return this.baseCampaign.campaignInventoryItems.some(x => x.inventoryItem != null ? x.inventoryItem.conversionTracking : 
                                                                    (x.inventoryItemNew != null ? x.inventoryItemNew.conversionTracking : false));
    }
    display() {
        if (!this.displayShow)
            this.displayShow = true;
        else if (this.displayShow)
            this.displayShow = false;
    }

    facebook() {
        if (!this.facebookShow)
            this.facebookShow = true;
        else if (this.facebookShow)
            this.facebookShow = false;
    }

    other() {
        if (!this.otherShow)
            this.otherShow = true;
        else if (this.otherShow)
            this.otherShow = false;
    }

    removeCreative(creative: ICreativeDto) {
        let indexCampaign = this.baseCampaign.creatives.indexOf(creative);
        let indexCreative = this.facebookStaticCreative.indexOf(creative);
        this.baseCampaign.creatives.splice(indexCampaign, 1);
        this.facebookStaticCreative.splice(indexCreative, 1);
    }

    setMinStartDate(index: number){

       if(index == 1){
        this.baseCampaign.creatives.forEach(element => {
            if(element.creativeGroup == 1){
                element.flightStartDate = new Date(this.baseCampaign.startDate);
            }
        });

        this.creativeFlightsDates[0].flightStartDate = new Date(this.baseCampaign.startDate);

        return moment(this.baseCampaign.startDate).toDate();
       }

       else if (this.baseCampaign.creatives.filter(x => x.creativeGroup == index - 1)[0].flightEndDate != '' && this.baseCampaign.creatives.filter(x => x.creativeGroup == index - 1)[0].flightEndDate != null){
        this.baseCampaign.creatives.forEach(element => {
            if(element.creativeGroup == index){
                element.flightStartDate = moment(new Date(this.creativeFlightsDates[index-2].flightEndDate)).add(1, 'days').toDate();
            }
        });

        this.creativeFlightsDates[index-1].flightStartDate = moment(new Date(this.creativeFlightsDates[index-2].flightEndDate)).add(1, 'days').toDate();

        return moment(new Date(this.baseCampaign.creatives.filter(x => x.creativeGroup == index - 1)[0].flightEndDate)).add(1, 'days').toDate();
       }

       else if (this.baseCampaign.creatives.filter(x => x.creativeGroup == index - 1)[0].flightStartDate != '' && this.baseCampaign.creatives.filter(x => x.creativeGroup == index - 1)[0].flightStartDate != null){
        return moment(new Date(this.baseCampaign.creatives.filter(x => x.creativeGroup == index - 1)[0].flightStartDate)).add(2, 'days').toDate();
       }

       else{
        return moment(new Date(this.baseCampaign.startDate)).add(index + 2, 'days').toDate();
       }
    }

    setMaxStartDate(index: number){

        if(!this.baseCampaign.creatives.find(x => x.creativeGroup == index + 1) && (this.creativeFlightsDates[index-1].flightEndDate == '' || this.creativeFlightsDates[index-1].flightEndDate == null)){
            return moment(new Date(this.baseCampaign.endDate)).add(-1, 'days').toDate();
        }

        else if(this.creativeFlightsDates[index-1].flightEndDate != '' && this.creativeFlightsDates[index-1].flightEndDate != null){
            return moment(new Date(this.creativeFlightsDates[index-1].flightEndDate)).add(-1, 'days').toDate();
        }

        else if(this.creativeFlightsDates[index].flightStartDate != '' && this.creativeFlightsDates[index].flightStartdDate != null){
            return moment(new Date(this.creativeFlightsDates[index].flightStartDate)).add(-2, 'days').toDate();
        }

        else{
            return moment(new Date(this.baseCampaign.endDate)).toDate();
        }
    }

    setMinEndDate(index: number){

         if (!this.baseCampaign.creatives.some(x => x.creativeGroup == index + 1)){

            this.baseCampaign.creatives.forEach(element => {
                if(element.creativeGroup == index){
                    element.flightEndDate = new Date(this.baseCampaign.endDate);
                }
            });

            this.creativeFlightsDates[index-1].flightEndDate = new Date(this.baseCampaign.endDate);

            return new Date(this.baseCampaign.endDate);
        }

        else if (this.creativeFlightsDates[index - 1].flightStartDate != '' && this.creativeFlightsDates[index - 1].flightStartDate != null){
            return moment(new Date(this.creativeFlightsDates[index - 1].flightStartDate)).add(1, 'days').toDate();
        }

        else if (this.creativeFlightsDates[index - 2] && this.creativeFlightsDates[index - 2].flightEndDate != '' && this.creativeFlightsDates[index - 1].flightEndDate != null){
            return moment(new Date(this.creativeFlightsDates[index - 2].flightEndDate)).add(2, 'days').toDate();
        }

        else if (this.creativeFlightsDates[index - 2] && this.creativeFlightsDates[index - 2].flightStartDate != '' && this.creativeFlightsDates[index - 1].flightStartDate != null){
            return moment(new Date(this.creativeFlightsDates[index - 2].flightStartDate)).add(3, 'days').toDate();
        }

        else {
            return moment(new Date(this.baseCampaign.startDate)).add(index * 2, 'days').toDate(); 
        }
    }

    setMaxEndDate(index: number){
        
        if(!this.baseCampaign.creatives.some(x => x.creativeGroup == index + 1)){
            return new Date(this.baseCampaign.endDate);
        }

        else if (this.creativeFlightsDates[index].flightStartDate != '' && this.creativeFlightsDates[index].flightStartDate != null){
            return moment(new Date(this.creativeFlightsDates[index].flightStartDate)).add(-1, 'days').toDate();
        }

        else{
            return moment(new Date(this.baseCampaign.endDate)).add(-2 * (this.numberOfFlights.length - index), 'days').toDate();
        }
    }

    facebookStaticCreativeLengthCheck(creativeGroup){
       return this.facebookStaticCreative.filter(x => x.creativeGroup == creativeGroup).length;
    }

    geographicChanges(event: IGeographicTargetingJson) {
        if (!!event) {
            this.baseCampaign.geographicTargetingJson = event;
        }
    }

    creativeFlightDateChange(event, creativeGroup: number, isStartDate: boolean){

        this.baseCampaign.creatives.forEach(x => {
            if(x.creativeGroup == creativeGroup && isStartDate){
                x.flightStartDate = event;
                this.creativeFlightsDates[creativeGroup-1].flightStartDate = event;
            }

            else if (x.creativeGroup == creativeGroup-1 && isStartDate){
                x.flightEndDate = moment(new Date(event)).add(-1, 'days').toDate();
                this.creativeFlightsDates[creativeGroup-2].flightEndDate = moment(new Date(event)).add(-1, 'days').toDate();
            }

            else if (x.creativeGroup == creativeGroup && !isStartDate){
                x.flightEndDate = event;
                this.creativeFlightsDates[creativeGroup-1].flightEndDate = event;
            }

            else if(x.creativeGroup == creativeGroup + 1 && !isStartDate){
                x.flightStartDate = moment(new Date(event)).add(1, 'days').toDate();
                this.creativeFlightsDates[creativeGroup].flightStartDate = moment(new Date(event)).add(1, 'days').toDate();
            }
        });

        this.creativeFlightsDates.forEach(x => {
            x.flightStartDateMin = this.setMinStartDate(x.creativeGroup),
            x.flightStartDateMax = this.setMaxStartDate(x.creativeGroup),
            x.flightEndDateMin = this.setMinEndDate(x.creativeGroup),
            x.flightEndDateMax = this.setMaxEndDate(x.creativeGroup)
        })
    }

    updateIsLandingUrlOk(isValid){
        this.isLandingUrlOk = isValid;
    }

    navigateToReportPage(){
        if(!!this.baseCampaign.id)
            this.router.navigate(['/campaign/' + this.baseCampaign.id + '/reporting']);
    }
}
